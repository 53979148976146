<script setup>
import { useDialogPluginComponent } from 'quasar';

const { dialogRef: dialogElement, onDialogHide } = useDialogPluginComponent();

const emit = defineEmits([
    ...useDialogPluginComponent.emits
]);

const dialogModel = defineModel({
    type: Boolean,
    default: false
});

function reloadApp() {
    window.location.reload();
}
</script>

<template>
    <q-dialog ref="dialogElement" @hide="onDialogHide" v-model="dialogModel" class="app-updated-service-dialog" persistent>
        <q-card class="card flex column justify-center" flat>
            <q-card-section class="q-pa-xl">
                <div class="card-container text-center">
                    <div>
                        <q-spinner-ball color="primary" size="xl"/>
                    </div>
                    <h5 class="text-h5 text-weight-medium">
                        The app has been updated
                    </h5>
                    <p>
                        We've rolled out a new version of the app, which was updated in the background. Please click "reload", or refresh your browser window to continue
                        using the app.
                    </p>
                    <q-btn @click="reloadApp" label="Reload" color="primary" class="q-mt-md" unelevated/>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<style lang="scss" scoped>
.card-container {
    max-width: 350px;
}
</style>
