import { RequestManager } from '@jaseeey/request-manager';
import { api, axiosSourceID } from 'boot/axios';
import { DateTime, Duration } from 'luxon';
import { defineStore } from 'pinia';
import { useChannelManager } from 'src/composables/channel-manager';
import { TaskStartError, TaskStopError, TaskSwitchError } from 'src/libs/client-error';
import { Activity } from 'src/models/activity.js';
import { Task, TaskStatus } from 'src/models/task.js';
import TaskService from 'src/services/task-service.js';
import { DateRange, resolveDateRange } from 'src/utils/datetime-util.js';
import { useActivityStore } from 'stores/activity-store';
import { useAuthStore } from 'stores/auth-store.js';
import { useClientStore } from 'stores/client-store.js';
import { useTagStore } from 'stores/tag-store.js';
import { computed, ref } from 'vue';

export const useTaskStore = defineStore('task', () => {
    const authStore = useAuthStore();
    const activityStore = useActivityStore();

    /** @type {Ref<UnwrapRef<Task[]>>} */
    const tasks = ref([]);
    const tasksDirty = ref(false);
    const tasksLoadedAt = ref(null);

    const activeTaskID = ref(null);
    const currentTaskID = ref(null);
    const waitingTaskID = ref(null);

    const filters = ref({
        dateRange: resolveDateRange(DateRange.LAST_14_DAYS),
        summary: '',
        tags: []
    });
    const filterActiveTasksOnly = ref(false);

    const doesFilterContainDoneTag = computed(() => filters.value.tags.some(tag => tag.label === 'Done' && tag.isGlobal));

    const hasDateRangeFilter = computed(() => filters.value.dateRange?.from && filters.value.dateRange?.to);
    const hasSummaryFilter = computed(() => filters.value.summary.trim());
    const hasTagsFilter = computed(() => filters.value.tags.length > 0);

    const hasTasks = computed(() => tasks.value.length > 0);
    const hasActiveTask = computed(() => !!activeTaskID.value);

    const tasksCount = computed(() => {
        return tasks.value ? tasks.value.length : 0;
    });

    const taskNotesCount = computed(() => {
        return tasks.value.filter(t => t.notes && t.notes.length > 0).length;
    });

    const sortedTasks = computed(() => {
        const getValue = (dateTime) => {
            if (dateTime === null) return 0;
            if (dateTime === Infinity) return Infinity;
            return dateTime.toUnixInteger();
        };
        return tasks.value.sort((taskA, taskB) => {
            if (getValue(taskA?.endDate) > getValue(taskB?.endDate)) return -1;
            if (getValue(taskA?.endDate) < getValue(taskB?.endDate)) return 1;
            return 0;
        }).filter(t => {
            return !t.deletedAt;
        });
    });

    const recentTasks = computed(() => {
        return sortedTasks.value.slice(0, 10);
    });

    const filteredTasksByDateRange = computed(() => {
        const result = [];
        for (const t of sortedTasks.value) {
            if (hasDateRangeFilter.value
                && !t.hasActivityWithinDateRange(filters.value.dateRange.from, filters.value.dateRange.to)
                && t.status !== TaskStatus.NOT_STARTED) {
                result.push(t);
            }
        }
        return result;
    });

    const filteredTasksBySummary = computed(() => {
        const result = [];
        for (const t of sortedTasks.value) {
            if (hasSummaryFilter.value && !t.summary.toLowerCase().includes(filters.value.summary.toLowerCase())) {
                result.push(t);
            }
        }
        return result;
    });

    const filteredTasksByTags = computed(() => {
        const result = [];
        for (const t of sortedTasks.value) {
            if (hasTagsFilter.value && !t.hasAllTags(filters.value.tags)) {
                result.push(t);
            }
        }
        return result;
    });

    const filteredTasksByActiveStatus = computed(() => {
        const result = [];
        for (const t of sortedTasks.value) {
            if (t.id === activeTaskID.value || !t.isDone || doesFilterContainDoneTag.value || !filterActiveTasksOnly.value) {
                result.push(t);
            }
        }
        return result;
    });

    const filteredTasks = computed(() => {
        const _tasks = [];
        for (const t of sortedTasks.value) {
            const isTaskFiltered = filteredTasksByDateRange.value.includes(t)
                || filteredTasksBySummary.value.includes(t)
                || filteredTasksByTags.value.includes(t)
                || !filteredTasksByActiveStatus.value.includes(t);
            if (isTaskFiltered) {
                continue;
            }
            if (t.id === activeTaskID.value || !t.isDone || doesFilterContainDoneTag.value || !filterActiveTasksOnly.value) {
                _tasks.push(t);
            }
        }
        return _tasks;
    });

    const filteredTasksCount = computed(() => {
        return filteredTasks.value.length;
    });

    const filteredTasksTotalDuration = computed(() => {
        let totalDuration = Duration.fromMillis(0);
        for (const task of filteredTasks.value) {
            if (!hasDateRangeFilter.value) {
                totalDuration = totalDuration.plus(task.totalDuration);
                continue;
            }
            const duration = task.getTotalDurationWithinDateRange(filters.value.dateRange.from, filters.value.dateRange.to);
            totalDuration = totalDuration.plus(duration);
        }
        return totalDuration;
    });

    const filteredTaskNotes = computed(() => {
        return filteredTasks.value.filter(t => {
            return t.notes && t.notes.length > 0;
        });
    });

    const filteredTaskNotesCount = computed(() => {
        return filteredTaskNotes.value.length;
    });

    const draftTasks = computed(() => {
        return tasks.value.filter(task => !task.isPersisted());
    });

    const activeTask = computed(() => {
        return findTaskByID(activeTaskID.value);
    });

    const currentTask = computed(() => {
        return findTaskByID(currentTaskID.value);
    });

    const waitingTask = computed(() => {
        return findTaskByID(waitingTaskID.value);
    });

    const activeActivity = computed(() => {
        return activeTask.value?.activeActivity;
    });

    const isCurrentTaskRunning = computed(() => {
        return currentTaskID.value ? isTaskRunning.value(currentTask.value) : false;
    });

    const isTaskRunning = computed(() => {
        return task => task.id === activeTaskID.value;
    });

    function tasksInvalidated() {
        return tasksDirty.value
            || !tasksLoadedAt.value
            || DateTime.now().diff(tasksLoadedAt.value, [ 'minutes' ]).minutes >= 5;
    }

    function invalidateTasks() {
        tasksDirty.value = true;
    }

    function resetDateRangeFilter() {
        filters.value.dateRange = resolveDateRange(DateRange.LAST_14_DAYS);
    }

    function resetSummaryFilter() {
        filters.value.summary = '';
    }

    function resetTagsFilter() {
        filters.value.tags.length = 0;
    }

    function resetAllFilters() {
        resetDateRangeFilter();
        resetSummaryFilter();
        resetTagsFilter();
    }

    async function appendTagFilter(tag) {
        const clientStore = useClientStore();
        const tagStore = useTagStore();
        await Promise.all([ clientStore.loadClients(), tagStore.loadTags() ]);
        const loadedTag = tag.type === 'client'
            ? clientStore.clients.find(t => t.label === tag.label)
            : tagStore.tags.find(t => t.label === tag.label);
        if (filters.value.tags.indexOf(loadedTag) === -1) {
            filters.value.tags.push(loadedTag);
        }
    }

    async function switchActiveTask() {
        const _activeTask = findTaskByID(activeTaskID.value);
        const _waitingTask = findTaskByID(waitingTaskID.value);
        if (!_activeTask || !_waitingTask) {
            throw new TaskSwitchError('Must have an active task and a waiting task to switch');
        }
        // Switch the active task immediately to provide instant feedback in the UI. It just means this will need to be
        // reverted if the server requests fail in the next steps.
        clearWaitingTask();
        setActiveTask(_waitingTask);
        try {
            await endActiveTask(_activeTask);
        }
        catch (err) {
            setActiveTask(_activeTask);
            throw new TaskStopError(err, 'Cannot stop task: ID ' + _activeTask.id);
        }
        try {
            await startTask(_waitingTask);
        }
        catch (err) {
            clearActiveTask();
            throw new TaskStartError(err, 'Cannot start task: ID ' + _waitingTask.id);
        }
    }

    async function startTask(task) {
        if (hasActiveTask.value) {
            setWaitingTask(task);
            return;
        }
        clearWaitingTask();
        setActiveTask(task);
        try {
            await activityStore.saveActivity(task.id, new Activity());
        }
        catch (err) {
            clearActiveTask();
            throw new TaskStartError(err, 'Cannot start task: ID ' + task.id);
        }
    }

    async function endActiveTask(task = null) {
        if (!hasActiveTask.value) {
            return;
        }
        task ??= activeTask.value;
        const activity = task.activeActivity;
        try {
            if (activity && activity.durationAsMinutes < 1) {
                await activityStore.deleteActivity(task.id, activity);
            }
            else if (activity) {
                task.endActivity(activity);
                await activityStore.saveActivity(task.id, activity);
            }
            clearActiveTask();
        }
        catch (err) {
            throw new TaskStopError(err, 'Cannot stop task: ID' + task.id);
        }
    }

    function setActiveTask(task) {
        activeTaskID.value = task.id;
    }

    function clearActiveTask() {
        activeTaskID.value = null;
    }

    function setCurrentTask(task) {
        currentTaskID.value = task.id;
    }

    function clearCurrentTask() {
        currentTaskID.value = null;
    }

    function setWaitingTask(task) {
        waitingTaskID.value = task.id;
    }

    function clearWaitingTask() {
        waitingTaskID.value = null;
    }

    function mapTaskTags(task) {
        const clientStore = useClientStore();
        const tagStore = useTagStore();
        clientStore.appendClients(task.tags.filter(t => t.client).map(t => clientStore.transformTagToClient(t)));
        tagStore.appendTags(task.tags);
        task.tags = task.tags.map(t => tagStore.tags.find(_t => _t.id === t.id) ?? t);
    }

    async function loadTasks() {
        if (!authStore.isAuthenticated || !tasksInvalidated()) {
            return;
        }
        const resources = (await RequestManager.call(api, 'get', '/tasks')).data;
        const preparedTasks = [];
        clearActiveTask();
        for (const r of resources) {
            const { activities: activitiesResources, ...taskResource } = r;
            const task = Object.assign(Task.fromObject(taskResource), { deletedAt: taskResource.deletedAt });
            preparedTasks.push(task);
            mapTaskTags(task);
            activityStore.importAllActivities(task.id, activitiesResources);
            if (task.activeActivity) {
                setActiveTask(task);
            }
        }
        tasks.value = preparedTasks;
        tasksDirty.value = false;
        tasksLoadedAt.value = DateTime.now();
    }

    async function loadTask(taskID) {
        if (!authStore.isAuthenticated) {
            return;
        }
        const resource = (await RequestManager.call(api, 'get', `/tasks/${taskID}`)).data;
        const { activities: activitiesResources, ...taskResource } = resource;
        const preparedTask = Task.fromObject(resource);
        const loadedTask = findTaskByID(taskID);
        const updatedTask = loadedTask
            ? Object.assign(loadedTask, preparedTask)
            : tasks.value[tasks.value.push(preparedTask) - 1];
        mapTaskTags(updatedTask);
        activityStore.importAllActivities(taskResource.id, activitiesResources);
        if (updatedTask.activeActivity) {
            setActiveTask(updatedTask);
        }
    }

    function findTaskByID(taskID) {
        return tasks.value.find(t => +t.id === +taskID);
    }

    function findActivitiesByID(activityIDs) {
        const activities = [];
        for (let i = 0; i < tasks.value.length; i++) {
            for (let j = 0; j < tasks.value[i].activities.length; j++) {
                if (!activityIDs.some(id => +id === +tasks.value[i].activities[j].id)) continue;
                activities.push(tasks.value[i].activities[j]);
            }
            // If all activities have been found, then no point iterating any further.
            if (activities.length === activityIDs.length) break;
        }
        return activities;
    }

    function createTask(summary = null) {
        const task = new Task();
        tasks.value.push(task);
        if (summary) {
            task.summary = summary;
        }
        return task;
    }

    async function saveTask(task, startOnSave = false) {
        task ??= currentTask.value;
        if (!authStore.isAuthenticated) {
            return;
        }
        try {
            const resource = task.isPersisted()
                ? await TaskService.updateTask(task)
                : await TaskService.createTask(task);
            Object.assign(task, Task.fromObject(resource));
            // Push the clients and tags into relevant stores to save the need to reload later.
            useTagStore().appendTags(task.tags);
            useClientStore().appendClients(task.tags.filter(t => t.client).map(t => useClientStore().transformTagToClient(t)));
            if (startOnSave) {
                await startTask(task);
            }
        }
        catch (err) {
            throw err;
        }
    }

    async function setTaskAsDone(task) {
        const tagStore = useTagStore();
        await tagStore.loadTags();
        const globalDoneTag = tagStore.tags.find(t => t.label === 'Done' && t.isGlobal);
        if (!globalDoneTag) {
            throw new Error('Tag could not be found: Done');
        }
        task.tags.push(globalDoneTag);
        await saveTask(task);
    }

    async function unsetTaskAsDone(task) {
        const doneTagIndex = task.tags.findIndex(t => t.label === 'Done' && t.isGlobal);
        if (doneTagIndex !== -1) {
            task.tags.splice(doneTagIndex, 1);
        }
        await saveTask(task);
    }

    async function deleteTask(task) {
        task ??= currentTask.value;
        if (task.id === activeTaskID.value) await endActiveTask();
        if (task.isPersisted()) await TaskService.deleteTask(task.id);
        tasks.value.splice(tasks.value.indexOf(task), 1);
    }

    async function restoreTask(task) {
        if (!authStore.isAuthenticated) {
            tasks.value.push(task);
            return;
        }
        const resource = await TaskService.restoreTask(task.id);
        tasks.value.push(Task.fromObject(resource));
    }

    function setIsInvoicedForTaskActivities(activityIDs, isInvoiced) {
        for (const activity of findActivitiesByID(activityIDs)) {
            activity.isInvoiced = isInvoiced;
        }
    }

    function removeTagFromAllTasks(tag) {
        const taskIDs = [];
        for (const task of tasks.value) {
            if (task.tags.includes(tag)) {
                task.tags.splice(task.tags.indexOf(tag), 1);
                taskIDs.push(task.id);
            }
        }
        return taskIDs;
    }

    async function watchTaskEvents() {
        if (!authStore.isAuthenticated) {
            return;
        }
        const channelManager = useChannelManager();
        await channelManager.subscribeToAvailableChannels();
        // Register channel events, however, ensure that the event is ignored if the source is this client, as the
        // original request has likely been handled via REST.
        [ 'task_created', 'task_updated' ].forEach(event => {
            channelManager.registerChannelEvent('private', event, data => {
                data.source !== axiosSourceID && _processMergeTaskEvent(data.resource);
            });
        });
        [ 'task_activity_created', 'task_activity_updated' ].forEach(event => {
            channelManager.registerChannelEvent('private', event, data => {
                data.source !== axiosSourceID && _processMergeTaskActivityEvent(data.resource);
            });
        });
        channelManager.registerChannelEvent('private', 'task_deleted', data => {
            data.source !== axiosSourceID && _processDeleteTaskEvent(data.resource);
        });
        channelManager.registerChannelEvent('private', 'task_activity_deleted', data => {
            data.source !== axiosSourceID && _processDeleteTaskActivityEvent(data.resource);
        });
    }

    function _processMergeTaskEvent(resource) {
        const loadedTask = tasks.value.find(t => +t.id === +resource.id);
        if (!loadedTask) {
            tasks.value.push(Task.fromObject(resource));
            return;
        }
        // Copy the activities from the loaded task to the resource, as the payload received from the websocket channel
        // always presents an empty array for this property, meaning they will be wiped from the store.
        resource.activities = loadedTask.activities;
        Object.assign(loadedTask, Task.fromObject(resource));
    }

    function _processDeleteTaskEvent(resource) {
        const loadedTask = findTaskByID(resource.id);
        if (!loadedTask) {
            return;
        }
        if (loadedTask === activeTask.value) {
            clearActiveTask();
        }
        tasks.value.splice(tasks.value.indexOf(loadedTask), 1);
    }

    async function _processMergeTaskActivityEvent(resource) {
        const taskID = resource.taskId;
        const loadedTask = findTaskByID(taskID) ?? await loadTask(taskID);
        if (!loadedTask) {
            return;
        }
        let loadedActivity = loadedTask.findActivityByID(resource.id);
        const activityIsActive = loadedActivity
            ? loadedActivity === activeActivity.value
            : false;
        if (!loadedActivity) {
            loadedActivity = Activity.fromObject(resource);
            loadedTask.activities.push(loadedActivity);
        }
        else {
            Object.assign(loadedActivity, Activity.fromObject(resource));
        }
        if (activityIsActive && !loadedActivity.isRunning()) {
            clearActiveTask();
        }
        if (loadedActivity.isRunning()) {
            setActiveTask(loadedTask);
        }
    }

    function _processDeleteTaskActivityEvent(resource) {
        const loadedTask = findTaskByID(resource.taskId);
        if (!loadedTask) {
            return;
        }
        const loadedActivity = loadedTask.activities.find(a => +a.id === +resource.id);
        if (loadedActivity === activeActivity.value) {
            clearActiveTask();
        }
        if (loadedActivity) {
            loadedTask.deleteActivity(loadedActivity);
        }
    }

    function unwatchTaskEvents() {
        const channelManager = useChannelManager();
        channelManager.unsubscribeFromAllChannels();
    }

    // Initialize the websocket connection and subscribe to task events on the user's private channel.
    setTimeout(() => watchTaskEvents(), 0);

    function $purgePersistedState() {
        // As this event is triggered when the session is unauthorized - not when the user is transitioning from
        // authorized to unauthorized - we only want to purge persisted tasks, and leave any non-persisted tasks within
        // the store.
        for (let i = tasks.value.length - 1; i >= 0; i--) {
            const t = tasks.value[i];
            if (!t.isPersisted()) continue;
            if (t.id === activeTaskID.value) clearActiveTask();
            if (t.id === currentTaskID.value) clearCurrentTask();
            tasks.value.splice(i, 1);
        }
        tasksDirty.value = false;
        tasksLoadedAt.value = null;
    }

    activityStore.$subscribe(mutation => {
        // Only track when an activity is deleted, otherwise ignore it.
        if (typeof mutation.events !== 'object'
            || mutation.events.type !== 'set'
            || mutation.events.key !== 'length'
            || mutation.events.newValue >= mutation.events.oldValue
            || !Array.isArray(mutation.events.target)) {
            return;
        }
        const taskIDs = new Set(mutation.events.target.map(a => a.taskId));
        for (const tID of taskIDs) {
            if (tID !== activeTaskID.value) {
                continue;
            }
            clearActiveTask();
        }
    });

    return {
        tasks,
        tasksDirty,
        tasksLoadedAt,
        activeTaskID,
        currentTaskID,
        waitingTaskID,
        filters,
        filterActiveTasksOnly,
        hasDateRangeFilter,
        hasSummaryFilter,
        hasTagsFilter,
        hasTasks,
        hasActiveTask,
        tasksCount,
        sortedTasks,
        recentTasks,
        filteredTasks,
        filteredTaskNotes,
        filteredTasksCount,
        filteredTasksTotalDuration,
        filteredTaskNotesCount,
        taskNotesCount,
        draftTasks,
        activeTask,
        currentTask,
        waitingTask,
        activeActivity,
        isCurrentTaskRunning,
        isTaskRunning,
        invalidateTasks,
        resetTagsFilter,
        resetAllFilters,
        appendTagFilter,
        switchActiveTask,
        startTask,
        endActiveTask,
        setCurrentTask,
        clearCurrentTask,
        clearWaitingTask,
        loadTasks,
        loadTask,
        findTaskByID,
        createTask,
        saveTask,
        setTaskAsDone,
        unsetTaskAsDone,
        deleteTask,
        restoreTask,
        setIsInvoicedForTaskActivities,
        removeTagFromAllTasks,
        watchTaskEvents,
        unwatchTaskEvents,
        $purgePersistedState
    };
});
