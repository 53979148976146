import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import InvoiceService from 'src/services/invoice-service.js';
import { convertToDateTime } from 'src/utils/datetime-util.js';
import { useAuthStore } from 'stores/auth-store.js';

export const useInvoiceStore = defineStore('invoice', {
    state: () => ({
        _invoicesDirty: false,
        _invoicesLoadedAt: null,
        _invoices: new Set(),
        currentInvoice: null,
        filters: {
            dateRange: {
                from: null,
                to: null
            },
            clientTag: null,
            status: null
        }
    }),
    getters: {
        invoices(state) {
            return Array.from(state._invoices);
        },

        totalInvoices() {
            return this.invoices.length;
        },

        hasInvoices() {
            return this.totalInvoices > 0;
        },

        sortedInvoices() {
            return this.invoices.sort((invoiceA, invoiceB) => {
                return InvoiceService.sortByStatusOverdueAsPriority(invoiceA, invoiceB)
                    || InvoiceService.sortByDescendingInvoiceNumber(invoiceA, invoiceB);
            });
        },

        hasDateRangeFilter() {
            return this.filters.dateRange?.from !== null && this.filters.dateRange?.to !== null;
        },

        hasClientTagFilter(state) {
            return state.filters.clientTag !== null;
        },

        hasStatusFilter(state) {
            return state.filters.status !== null;
        },

        hasFilters() {
            return this.hasDateRangeFilter
                || this.hasClientTagFilter
                || this.hasStatusFilter;
        },

        filteredInvoices() {
            return this.sortedInvoices.filter(i => !this.hasFilters
                || ((!this.hasDateRangeFilter || (this.hasDateRangeFilter && i.invoiceDate >= this.filters.dateRange.from && i.invoiceDate <= this.filters.dateRange.to))
                    && (!this.hasClientTagFilter || (this.hasClientTagFilter && i.clientId === this.filters.clientTag.client.id))
                    && (!this.hasStatusFilter || (this.hasStatusFilter && i.status === this.filters.status))));
        },

        totalFilteredInvoices() {
            return this.filteredInvoices.length;
        },

        hasFilteredInvoices() {
            return this.totalFilteredInvoices > 0;
        }
    },
    actions: {
        resetAllFilters() {
            this.filters.dateRange = { from: null, to: null };
            this.filters.clientTag = null;
            this.filters.status = null;
        },

        invoicesInvalidated() {
            return this._invoicesDirty
                || !this._invoicesLoadedAt
                || DateTime.now().diff(this._invoicesLoadedAt, [ 'minutes' ]).minutes >= 5;
        },

        invalidateInvoices() {
            this._invoicesDirty = true;
        },

        mapJSONToTypes(invoice) {
            invoice.createdAt = DateTime.fromISO(invoice.createdAt);
            invoice.updatedAt = DateTime.fromISO(invoice.updatedAt);
            // Parsing from JS Date is the most reliable method as it can automatically parse ISO 8601 date/time strings
            // without needing to pre-define the format.
            invoice.sentAt = invoice.sentAt ? DateTime.fromMillis(Date.parse(invoice.sentAt)) : null;
            invoice.invoiceDate = DateTime.fromMillis(Date.parse(invoice.invoiceDate));
            invoice.dueDate = invoice.dueDate ? DateTime.fromMillis(Date.parse(invoice.dueDate)) : null;
            invoice.payments = invoice.payments.map(p => {
                p.createdAt = DateTime.fromISO(p.createdAt);
                p.updatedAt = DateTime.fromISO(p.updatedAt);
                p.paidAt = DateTime.fromISO(p.paidAt);
                return p;
            });
            return invoice;
        },

        findInvoiceById(invoiceId) {
            return Array.from(this._invoices).find(i => i.id === invoiceId);
        },

        async createInvoice() {
            const invoice = (await api.post('/invoices', {
                clientId: null,
                invoiceNumber: null,
                invoiceDate: DateTime.now().toFormat('yyyy-MM-dd')
            })).data;
            this._invoices.add(this.mapJSONToTypes(invoice));
            return invoice;
        },

        async loadInvoices() {
            if (!useAuthStore().isAuthenticated || !this.invoicesInvalidated()) {
                return;
            }
            const invoices = (await api.get('/invoices')).data.map(i => this.mapJSONToTypes(i));
            this._invoices.clear();
            for (const invoice of invoices) {
                this._invoices.add(invoice);
            }
            this._invoicesDirty = false;
            this._invoicesLoadedAt = DateTime.now();
        },

        async loadInvoice(invoiceId) {
            const loadedInvoice = (await api.get(`/invoices/${invoiceId}`)).data;
            const cachedInvoice = Array.from(this._invoices).find(i => i.id === loadedInvoice.id);
            if (cachedInvoice) {
                Object.assign(cachedInvoice, this.mapJSONToTypes(loadedInvoice));
                return;
            }
            this._invoices.add(this.mapJSONToTypes(loadedInvoice));
        },

        async saveInvoice(invoice) {
            const invoiceAttrs = {
                id: invoice.id,
                sentAt: invoice.sentAt,
                clientId: invoice.clientId,
                invoiceNumber: invoice.invoiceNumber,
                invoiceDate: invoice.invoiceDate?.toFormat('yyyy-MM-dd') ?? null,
                dueDate: invoice.dueDate?.toFormat('yyyy-MM-dd') ?? null,
                reference: invoice.reference,
                notes: invoice.notes,
                items: invoice.items
            };
            const savedInvoice = invoice.createdAt
                ? (await api.put(`/invoices/${invoice.id}`, invoiceAttrs)).data
                : (await api.post('/invoices', invoiceAttrs)).data;
            const cachedInvoice = Array.from(this._invoices).find(i => i.id === savedInvoice.id);
            if (cachedInvoice) {
                Object.assign(cachedInvoice, this.mapJSONToTypes(savedInvoice));
                return;
            }
            this._invoices.add(this.mapJSONToTypes(savedInvoice));
        },

        async deleteInvoice(invoice) {
            if (invoice.createdAt) {
                await api.delete(`/invoices/${invoice.id}`);
            }
            this._invoices.delete(invoice);
        },

        async createAdjustment(invoiceID, adjustmentAttrs) {
            const resource = (await api.post(`/invoices/${invoiceID}/adjustments`, adjustmentAttrs)).data;
            const loadedInvoice = [ ...this._invoices ].find(i => i.id === invoiceID);
            loadedInvoice.adjustments.push(resource);
            return resource;
        },

        async deleteAdjustment(invoiceID, adjustmentID) {
            await api.delete(`/invoices/${invoiceID}/adjustments/${adjustmentID}`);
            const loadedInvoice = [ ...this._invoices ].find(i => i.id === invoiceID);
            loadedInvoice.adjustments.splice(loadedInvoice.adjustments.findIndex(a => a.id === adjustmentID), 1);
        },

        async recordPayment(invoice, paymentAttrs) {
            const resource = (await api.post(`/invoices/${invoice.id}/payments`, paymentAttrs)).data,
                cachedInvoice = Array.from(this._invoices).find(i => i.id === invoice.id);
            if (!cachedInvoice) return;
            cachedInvoice.payments.push(convertToDateTime(resource, 'createdAt', 'updatedAt', 'paidAt'));
            // TODO This is intended to reload the invoice status, but reloading the entire invoice is a little inefficient.
            //   Maybe an endpoint can be created to only get the invoice status?
            await this.loadInvoice(invoice.id);
        },

        async deletePayment(invoice, payment) {
            await api.delete(`/invoices/${invoice.id}/payments/${payment.id}`);
            const cachedInvoice = Array.from(this._invoices).find(i => i.id === invoice.id);
            if (!cachedInvoice) return;
            cachedInvoice.payments.splice(cachedInvoice.payments.indexOf(payment), 1);
            // TODO This is intended to reload the invoice status, but reloading the entire invoice is a little inefficient.
            //   Maybe an endpoint can be created to only get the invoice status?
            await this.loadInvoice(invoice.id);
        },

        async previewInvoice(invoice) {
            const response = await api.post(`/invoices/${invoice.id}/preview`);
            return response.data.url;
        },

        async sendInvoice(invoice, options) {
            await api.post(`/invoices/${invoice.id}/send`, {
                recipient: options.recipient,
                from: options.from,
                subject: options.subject,
                message: options.message,
                copyToUser: options.copyToUser,
                secure: options.secure
            });
        },

        async markInvoiceAsNew(invoice) {
            await this.saveInvoice({ ...invoice, sentAt: null, viewedAt: null });
        },

        async markInvoiceAsSent(invoice) {
            await this.saveInvoice({ ...invoice, sentAt: DateTime.now(), viewedAt: null });
        }
    }
});
