import Decimal from 'decimal.js';

export const FORMAT_FULL = 'full';
export const FORMAT_SHORT = 'short';
export const FORMAT_MINIMAL = 'minimal';

const definedFormats = {
    [FORMAT_FULL]: {
        hours: 'hour',
        minutes: 'minute',
        separator: ' ',
        plural: true
    },
    [FORMAT_SHORT]: {
        hours: 'hr',
        minutes: 'min',
        separator: ' ',
        plural: true
    },
    [FORMAT_MINIMAL]: {
        hours: 'h',
        minutes: 'm',
        separator: '',
        plural: false
    }
};

export function formatDurationAsDecimal(duration, decimalPlaces = 4) {
    duration = recalculateDurationUnits(duration, [ 'hours' ]);
    return new Decimal(duration.hours).toDP(decimalPlaces);
}

export function formatHumanDuration(duration, format = FORMAT_FULL) {
    duration = recalculateDurationUnits(duration);
    format = definedFormats[format];
    const parts = [];
    const units = [ 'hours', 'minutes' ];
    const separator = format.separator;
    for (const u of units) {
        const v = duration[u];
        if (v === 0 && units[units.length - 1] !== u) {
            continue;
        }
        const label = format[u] + (format.plural && (v === 0 || v > 1) ? 's' : '');
        parts.push(`${v}${separator}${label}`);
    }
    return parts.join(', ');
}

export function formatDurationHours(duration, length = 2) {
    if (!duration) {
        return '0'.padStart(length, '0');
    }
    duration = recalculateDurationUnits(duration);
    return duration.hours.toString().padStart(length, '0');
}

export function formatDurationMinutes(duration, length = 2) {
    if (!duration) {
        return '0'.padStart(length, '0');
    }
    duration = recalculateDurationUnits(duration);
    return duration.minutes.toString().padStart(length, '0');
}

export function formatDurationSeconds(duration, length = 2) {
    if (!duration) {
        return '0'.padStart(length, '0');
    }
    duration = recalculateDurationUnits(duration);
    return duration.seconds.toString().padStart(length, '0');
}

export function recalculateDurationUnits(duration, units = [ 'hours', 'minutes', 'seconds' ]) {
    return duration.shiftTo(...units);
}
