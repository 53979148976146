import Pusher from 'pusher-js';
import { boot } from 'quasar/wrappers';
import { pusherEnv, serverEnv } from 'src/config/env';

let pusher;

export default boot(({ app }) => {
    if (!process.env.CLIENT) {
        return;
    }
    if (process.env.DEV) {
        Pusher.logToConsole = true;
    }
    // Override XMLHttpRequest creation to allow including of cookies/credentials, as there's no config option to do so.
    Pusher.Runtime.createXHR = function () {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        return xhr;
    };
    pusher = new Pusher(pusherEnv.key, {
        cluster: pusherEnv.cluster,
        channelAuthorization: {
            headers: { 'Accept': 'application/json' },
            transport: 'ajax',
            endpoint: serverEnv.authPath + '/user/channels/verify'
        }
    });
    app.config.globalProperties.$pusher = pusher;
    app.provide('$pusher', pusher);
});

export { pusher };
