import { defineStore } from 'pinia';
import { Dark } from 'quasar';
import { useLocalStorageManager } from 'src/composables/local-storage-manager';
import { computed, ref, watchEffect } from 'vue';

export const useAppStore = defineStore('app', () => {
    const localStorageManager = useLocalStorageManager();
    const storeRef = localStorageManager.storeRef;

    const appUpdated = ref(false);
    const clientOffline = ref(false);
    const serverOffline = ref(false);

    const themeMode = storeRef('app/themeMode', 'auto');
    // This value is needed to be passed through to the HTML pages
    const isDarkModeEnabled = ref(Dark.isActive);

    const isHeaderRevealed = ref(true);
    const isLeftDrawerOpen = storeRef('app/leftDrawerOpen', false);
    const isLeftDrawerMinimized = storeRef('app/leftDrawerMinimized', false);

    const dismissUpdateNotification = ref(null);

    const hasUpdateNotification = computed(() => dismissUpdateNotification.value !== null);

    Dark.set(themeMode.value);

    function _toggleColorSchemeMetaTag() {
        if (!process.env.CLIENT) {
            return;
        }
        const metaTag = document.querySelector('meta[name="color-scheme"]');
        if (Dark.isActive && !metaTag) {
            const meta = document.createElement('meta');
            meta.name = 'color-scheme';
            meta.content = 'dark';
            document.head.appendChild(meta);
        }
        else if (!Dark.isActive && metaTag) {
            metaTag.remove();
        }
    }

    function setDarkModeEnabled(isEnabled) {
        isDarkModeEnabled.value = isEnabled;
    }

    function setHeaderRevealed(isEnabled) {
        isHeaderRevealed.value = isEnabled;
    }

    function setLeftDrawerOpen(isEnabled) {
        isLeftDrawerOpen.value = isEnabled;
    }

    function setLeftDrawerMinimized(isEnabled) {
        isLeftDrawerMinimized.value = isEnabled;
    }

    function setUpdateNotification(notificationInstance) {
        dismissUpdateNotification.value = notificationInstance;
    }

    function clearUpdateNotification() {
        if (dismissUpdateNotification.value) {
            dismissUpdateNotification.value();
        }
        setUpdateNotification(null);
    }

    function purgeAllLegacyPersistedStates() {
        // TODO Remove in future
        // This is to simply purge all local storage data which was persisted by the persisted state plugin. As this is
        // no longer used nor needed, it can actively be removed rather than waiting for the user to clear their data.
        const obsoleteStorageKeys = [
            'activity',
            'app',
            'auth',
            'client',
            'geo',
            'invoice',
            'layout',
            'notification',
            'tag',
            'task',
            'tax-rate'
        ];
        for (const k of obsoleteStorageKeys) {
            localStorage.getItem(k) && localStorageManager.remove(k);
        }
    }

    purgeAllLegacyPersistedStates();

    watchEffect(() => {
        themeMode.value = Dark.mode;
        _toggleColorSchemeMetaTag();
    });


    return {
        appUpdated,
        clientOffline,
        serverOffline,
        themeMode,
        isDarkModeEnabled,
        isHeaderRevealed,
        isLeftDrawerOpen,
        isLeftDrawerMinimized,
        hasUpdateNotification,
        setDarkModeEnabled,
        setHeaderRevealed,
        setLeftDrawerOpen,
        setLeftDrawerMinimized,
        setUpdateNotification,
        clearUpdateNotification
    };
});
