import { api } from 'boot/axios';

/**
 * RequestManager
 *
 * Manages and regulates HTTP requests to ensure that multiple requests to the same URL and method are not made
 * concurrently. When a request to a specific URL and method is in progress, subsequent requests to the same URL and
 * method will return the same promise, preventing duplicate requests.
 */
class RequestManager {

    /**
     * A Map to store active requests, with each key being a combination of URL and HTTP method and its corresponding
     * value being the Promise of the ongoing HTTP request.
     *
     * @type {Map<string, Promise>}
     */
    activeRequests = new Map();

    /**
     * Executes or retrieves an ongoing HTTP request based on the provided URL, method, payload, and configuration.
     *
     * @param {string} method - The HTTP method of the request (e.g., 'GET', 'POST').
     * @param {string} url - The URL of the HTTP request.
     * @param {Object} [data = {}] - The payload to send with the request. Relevant for methods like 'POST' or 'PUT'.
     * @param {Object} [config = {}] - Additional configuration for the request.
     * @returns {Promise} - The Promise of the HTTP request. If a request to the same URL and method is already in
     *   progress, returns the Promise of the ongoing request; otherwise, initiates a new request and returns its Promise.
     */
    async call(method, url, data = {}, config = {}) {
        const key = `${method}:${url}`;
        if (this.activeRequests.has(key)) {
            return this.activeRequests.get(key);
        }
        const request = api.request({ ...config, method, url, data }).finally(() => {
            this.activeRequests.delete(key);
        });
        this.activeRequests.set(key, request);
        return request;
    }
}

export default new RequestManager();
