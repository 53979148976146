import { colors } from 'quasar';
import { onUpdated, watch } from 'vue';
import { useRouter } from 'vue-router';

/**
 * Registers a watcher to set the body overflow to hidden when the route is changed, and back to visible once the DOM
 * has been updated. This is to prevent the scrollbar from appearing during the transition effects (i.e. face in/out).
 *
 * Note: To be called in the setup() hook of a page component where <transition> is used.
 */
export function hideScrollbarOnRouteChange() {
    const router = useRouter();
    watch(() => router.currentRoute.value.path, () => document.body.style.overflow = 'hidden');
    onUpdated(() => setTimeout(() => document.body.style.overflow = null, 300));
}

/**
 * Attempts to open the URL within the Cordova InAppBrowser if within context,
 * otherwise falls back to using the current tab. For more information on the
 * Cordova InAppBrowser: https://github.com/apache/cordova-plugin-inappbrowser
 *
 * @param {string} url
 * @param {Object} events
 * @param {Object} options
 * @return {InAppBrowser|WindowProxy}
 */
export function openUrl(url, events = {}, options = {}) {
    if (window.hasOwnProperty('cordova') && cordova.InAppBrowser) {
        options = prepareCordovaOptions(options);
        const iab = cordova.InAppBrowser.open(url, 'InAppBrowser', options);
        for (const [ name, callback ] of Object.entries(events)) {
            iab.addEventListener(name, callback);
        }
        return iab;
    }
    return window.open(url, '_self');
}

const defaultCordovaOptions = {
    location: true,
    beforeLoad: true,
    toolbarColor: colors.getPaletteColor('primary'),
    hideNavigationButtons: true,
    zoom: false
};

function prepareCordovaOptions(options) {
    options = Object.assign({}, defaultCordovaOptions, options);
    const preparedOptions = [];
    for (let [ option, value ] of Object.entries(options)) {
        option = option.toLowerCase();
        value = typeof value === 'boolean'
            ? (value ? 'yes' : 'no')
            : value;
        preparedOptions.push(`${option}=${value}`);
    }
    return preparedOptions.join(',');
}
