import { DateTime } from 'luxon';
import { generateDraftId } from 'src/utils/id-util.js';

export class Activity {

    id = generateDraftId();
    taskId = null;
    createdAt = null;
    updatedAt = null;
    isInvoiced = false;

    _startDate = null;
    _endDate = null;
    _duration = null;

    constructor(startDate = DateTime.now(), endDate = null) {
        this.initProxyStartAndEndDates(startDate, endDate);
    }

    get startDate() {
        return this._startDate.value;
    }

    set startDate(dateTime) {
        this._startDate.value = dateTime?.startOf('second');
    }

    get endDate() {
        return this._endDate.value;
    }

    set endDate(dateTime) {
        this._endDate.value = dateTime?.startOf('second') ?? null;
    }

    get duration() {
        if (this._duration === null) {
            return this.calculateDuration();
        }
        return this._duration;
    }

    /**
     * Gets the hour value of the duration.
     *
     * This will return only the hour value, not the full duration represented in hours. For example, a duration of
     * 1 hour and 25 minutes will simply return 1.
     *
     * @return {number}
     */
    get durationHours() {
        return this.duration.get('hours');
    }

    /**
     * Gets the minute value of the duration.
     *
     * This will return only the minute value, not the full duration represented in minutes. For example, a duration of
     * 1 hour and 25 minutes will simply return 25.
     *
     * @return {number}
     */
    get durationMinutes() {
        return this.duration.get('minutes');
    }

    /**
     * Gets the duration of the activity represented in hours only.
     *
     * @return {number}
     */
    get durationAsHours() {
        return this.duration.shiftTo('hours').get('hours');
    }

    /**
     * Gets the duration of the activity represented in minutes only.
     *
     * @return {number}
     */
    get durationAsMinutes() {
        return this.duration.shiftTo('minutes').get('minutes');
    }

    /**
     * Converts the given object to an Activity instance.
     *
     * @param {Object} o
     * @param {number} o.id
     * @param {number} o.taskId
     * @param {string} o.createdAt
     * @param {string} o.updatedAt
     * @param {string} o.startDate
     * @param {string|null} o.endDate
     * @param {boolean} o.isInvoiced
     */
    static fromObject(o) {
        const activity = new Activity();
        activity.id = o.id;
        activity.taskId = o.taskId;
        activity.createdAt = o.createdAt;
        activity.updatedAt = o.updatedAt;
        activity.startDate = DateTime.fromISO(o.startDate);
        activity.endDate = o.endDate ? DateTime.fromISO(o.endDate) : null;
        activity.isInvoiced = o.isInvoiced;
        return activity;
    }

    /**
     * Initializes the start and end date properties as Proxy instances, intended to perform a recalculation of the
     * duration when the values are changed. This reduces the impact on performance by not needing to calculate the
     * duration on-demand, repeatedly.
     *
     * @param {DateTime} startDate
     * @param {DateTime|null} endDate
     */
    initProxyStartAndEndDates(startDate, endDate) {
        const handler = {
            set: (subject, property, value) => {
                subject[property] = value;
                // Calculate the duration and set it in the class property if both startDate and endDate are valid.
                // This reduces the need to dynamically calculate the same duration over and over.
                this._duration = this.startDate && this.endDate
                    ? this.calculateDuration()
                    : null;
                return true;
            }
        };
        this._startDate = new Proxy({ value: startDate?.startOf('second') }, handler);
        this._endDate = new Proxy({ value: endDate?.startOf('second') ?? null }, handler);
    }

    calculateDuration() {
        return (this.endDate ?? DateTime.now().startOf('second'))
            .diff(this.startDate, [ 'hours', 'minutes', 'seconds' ]);
    }

    isRunning() {
        return this._startDate.value && !this._endDate.value;
    }

    isPersisted() {
        return !!this.createdAt;
    }

    isWithinDateRange(startDate, endDate) {
        startDate = startDate.startOf('day');
        endDate = endDate.endOf('day');
        return (this.startDate >= startDate && this.startDate <= endDate)
            || (this.endDate >= startDate && this.endDate <= endDate);
    }

    toJSON() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            startDate: this.startDate.toISO(),
            endDate: this.endDate ? this.endDate.toISO() : null,
            isInvoiced: this.isInvoiced
        };
    }
}
