import { DateTime, Duration } from 'luxon';
import { Activity } from 'src/models/activity.js';
import TagService from 'src/services/tag-service.js';
import { recalculateDurationUnits } from 'src/utils/duration-util.js';
import { generateDraftId } from 'src/utils/id-util.js';
import { useActivityStore } from 'stores/activity-store';

export const TaskStatus = {
    NOT_STARTED: 0,
    RUNNING: 1,
    COMPLETE: 2
};

export class Task {
    id = generateDraftId();
    createdAt = null;
    updatedAt = null;
    deletedAt = null;
    summary = 'Unnamed Task';
    notes = '';
    isDone = null;

    _tags = null;

    constructor() {
        this.initProxyTags([]);
    }

    get activities() {
        // TODO Remove temporary proxy through Activity store
        const activityStore = useActivityStore();
        return activityStore.activities.has(this.id)
            ? activityStore.activities.get(this.id)
            : activityStore.activities.set(this.id, []).get(this.id);
    }

    set activities(v) {
        // TODO Remove temporary proxy through Activity store
        const activityStore = useActivityStore();
        activityStore.activities.has(this.id)
            ? activityStore.activities.get(this.id).push(v instanceof Activity ? v : Activity.fromObject(v))
            : activityStore.activities.set(this.id, [ v instanceof Activity ? v : Activity.fromObject(v) ]);
    }

    get tags() {
        return this._tags.value;
    }

    set tags(v) {
        this._tags.value = v;
    }

    get status() {
        if (!this.startDate) return TaskStatus.NOT_STARTED;
        if (!this.endDate) return TaskStatus.RUNNING;
        return TaskStatus.COMPLETE;
    }

    get activeActivity() {
        if (!this.activities || this.activities.length === 0) {
            return null;
        }
        return this.activities.find(a => a.startDate && !a.endDate);
    }

    get startDate() {
        if (!this.activities?.length) {
            return null;
        }
        let startDate = null;
        for (const activity of this.activities) {
            if (startDate && activity.startDate >= startDate) continue;
            startDate = DateTime.fromObject(activity.startDate.toObject());
        }
        return startDate;
    }

    get endDate() {
        if (!this.activities?.length) {
            return null;
        }
        if (this.activeActivity) {
            return Infinity;
        }
        let endDate = null;
        for (const activity of this.activities) {
            if (endDate && activity.endDate <= endDate) continue;
            endDate = DateTime.fromObject(activity.endDate.toObject());
        }
        return endDate;
    }

    get totalDuration() {
        let duration = Duration.fromMillis(0);
        for (const activity of this.activities) {
            duration = duration.plus(activity.duration);
        }
        return recalculateDurationUnits(duration);
    }

    /**
     * Converts the given object to a Task instance.
     *
     * @param {Object} o - The task as an object.
     * @param {string} o.id - The unique ID of the task.
     * @param {string} o.createdAt - The ISO timestamp of when the task was created.
     * @param {string} o.updatedAt - The ISO timestamp of when the task was last updated.
     * @param {string} o.summary - The summary of the task.
     * @param {string} o.notes - The notes of the task.
     * @param {string} o.tags - The tags of the task.
     * @param {Object[]} o.activities - The activities of the task.
     * @param {boolean|null} o.isDone - Whether the task is set to done.
     */
    static fromObject(o) {
        const task = new Task();
        task.id = +o.id;
        task.createdAt = o.createdAt;
        task.updatedAt = o.updatedAt;
        task.summary = o.summary;
        task.notes = o.notes;
        task.isDone = o.isDone;
        task.tags = o.tags;
        // task.activities = o.activities.map(a => a instanceof Activity ? a : Activity.fromObject(a));
        return task;
    }

    /**
     * Initializes the tags property as a Proxy instance, intended to perform a sort of the tags when the array is updated or mutated.
     * This reduces the impact on performance by not needing to re-sort on-demand, repeatedly.
     *
     * @param {object[]} tags
     */
    initProxyTags(tags) {
        const handler = {
            set: (subject, property, value) => {
                // Sort the tags on set to reduce the need for sorting on-demand.
                if (Array.isArray(value)) {
                    TagService.sortTags(value);
                }
                subject[property] = value;
                return true;
            }
        };
        this._tags = new Proxy({ value: tags }, handler);
    }

    hasAllTags(tags) {
        return tags.every(t => this.tags.find(_t => _t.id === t.id));
    }

    findActivityByID(activityID) {
        return this.activities.find(a => +a.id === +activityID);
    }

    hasActivity(activity) {
        return this.activities.indexOf(activity) !== -1;
    }

    startActivity() {
        this.activities.push(new Activity());
    }

    endActivity(activity) {
        if (!activity || activity.endDate) {
            return;
        }
        const hours = activity.durationHours;
        const minutes = activity.durationMinutes;
        activity.endDate = activity.startDate.plus({ hours, minutes });
    }

    deleteActivity(activity) {
        const activityIndex = this.activities.indexOf(activity);
        if (activityIndex !== -1) {
            this.activities.splice(activityIndex, 1);
        }
    }

    hasActivityWithinDateRange(startDate, endDate) {
        return this.activities.some(a => a.isWithinDateRange(startDate, endDate));
    }

    getTotalDurationWithinDateRange(startDate, endDate) {
        let totalDuration = Duration.fromMillis(0);
        for (const activity of this.activities) {
            if (!activity.isWithinDateRange(startDate, endDate)) {
                continue;
            }
            // Ensure the duration is calculated within the specified date range to prevent double counting.
            const activityEndDate = activity.endDate ?? DateTime.now();
            const lowerDate = activity.startDate <= startDate ? startDate : activity.startDate;
            const upperDate = activityEndDate > endDate ? endDate.plus({ day: 1 }).startOf('day') : activityEndDate;
            const duration = upperDate.diff(lowerDate, [ 'hours', 'minutes', 'seconds' ]);
            totalDuration = totalDuration.plus(duration);
        }
        return totalDuration;
    }

    getActiveDuration(units = [ 'hours', 'minutes', 'seconds' ]) {
        let duration = Duration.fromMillis(0);
        for (const activity of this.activities) {
            if (!activity.isRunning()) {
                continue;
            }
            duration = duration.plus(activity.duration);
        }
        return recalculateDurationUnits(duration, units);
    }

    getCompletedDuration(units = [ 'hours', 'minutes', 'seconds' ]) {
        let duration = Duration.fromMillis(0);
        for (const activity of this.activities) {
            if (activity.isRunning()) {
                continue;
            }
            duration = duration.plus(activity.duration);
        }
        return recalculateDurationUnits(duration, units);
    }

    isPersisted() {
        return !!this.createdAt;
    }

    toJSON() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            summary: this.summary,
            notes: this.notes,
            tags: this.tags,
            activities: this.activities,
            isDone: this.isDone
        };
    }
}
