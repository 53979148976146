import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const usePlanStore = defineStore('plan', () => {
    const _plansLoadedAt = ref(null),
        _plansDirty = ref(false),
        plans = ref([]);

    const hasPlans = computed(() => plans.value.length > 0);

    const plansInvalidated = () => {
        return _plansDirty.value
            || !_plansLoadedAt.value
            || DateTime.now().diff(_plansLoadedAt.value, [ 'minutes' ]).minutes >= 5;
    };

    const invalidatePlans = () => {
        _plansDirty.value = true;
    };

    const loadAllPlans = async () => {
        const response = await api.get('/plans');
        plans.value.length = 0;
        for (const p of response.data) {
            plans.value.push(p);
        }
        _plansLoadedAt.value = DateTime.now();
        _plansDirty.value = false;
        return plans.value;
    };

    return {
        _plansLoadedAt,
        _plansDirty,
        plans,
        hasPlans,
        plansInvalidated,
        invalidatePlans,
        loadAllPlans
    };
});
