import { RequestManager } from '@jaseeey/request-manager';
import { api } from 'boot/axios';
import { defineStore } from 'pinia';
import { ObservableMap } from 'src/libs/observable-map';
import { Activity } from 'src/models/activity';
import TaskService from 'src/services/task-service';
import { ref } from 'vue';

export const useActivityStore = defineStore('activity', () => {
    const activities = ref(new ObservableMap());
    const activitiesUpdatedAt = ref(new Map());

    async function loadAllActivities(taskID) {
        importAllActivities(taskID, (await RequestManager.call(api, 'get', `/tasks/${taskID}/activities`)).data);
    }

    function importAllActivities(taskID, resources) {
        _clearAllActivities(taskID);
        const pendingActivities = [];
        for (const r of resources) {
            pendingActivities.push(Activity.fromObject(r));
        }
        TaskService.sortItemsByLatest(pendingActivities);
        activities.value.set(taskID, pendingActivities);
    }

    function _clearAllActivities(taskID) {
        activities.value.has(taskID)
            ? activities.value.get(taskID).length = 0
            : activities.value.set(taskID, []);
    }

    function newActivity(taskID, startDate = null) {
        const activity = new Activity(startDate);
        activity.taskId = taskID;
        return activity;
    }

    async function saveActivity(taskID, activity) {
        const updatedActivity = activity.isPersisted()
            ? await _updateActivity(taskID, activity)
            : await _createActivity(taskID, activity);
        _storeActivity(taskID, updatedActivity);
    }

    async function deleteActivity(taskID, activity) {
        await api.delete(`/tasks/${taskID}/activities/${activity.id}`);
        activities.value.has(taskID)
            ? activities.value.get(taskID).splice(activities.value.get(taskID).indexOf(activity), 1)
            : activities.value.set(taskID, []);
    }

    async function _createActivity(taskID, activity) {
        const resource = (await api.post(`/tasks/${taskID}/activities`, {
            startDate: activity.startDate,
            endDate: activity.endDate
        })).data;
        return Activity.fromObject(resource);
    }

    async function _updateActivity(taskID, activity) {
        const resource = (await api.put(`/tasks/${taskID}/activities/${activity.id}`, {
            id: activity.id,
            startDate: activity.startDate,
            endDate: activity.endDate
        })).data;
        return Activity.fromObject(resource);
    }

    function _storeActivity(taskID, activity, performSort = true) {
        const taskActivities = activities.value.get(taskID);
        if (!taskActivities) {
            activities.value.set(taskID, [ activity ]);
            return;
        }
        const loadedActivity = taskActivities.find(a => a.id === activity.id);
        loadedActivity
            ? Object.assign(loadedActivity, activity)
            : taskActivities.push(activity);
        if (performSort) {
            TaskService.sortItemsByLatest(activities.value.get(taskID));
        }
        activitiesUpdatedAt.value.set(taskID, Date.now());
    }

    // Subscribe to changes in the `activities` state, which is an ObservableMap. This subscription captures any runtime
    // changes (additions, updates, or deletions) to the activities. Upon any change, it updates the `activitiesUpdatedAt`
    // map with the current timestamp for the affected taskID (key). This mechanism allows components to reactively track
    // updates to activities with minimal resource usage, avoiding the need for deep-watching complex data structures
    // like Arrays or Objects.
    activities.value.subscribe((action, key) => activitiesUpdatedAt.value.set(key, Date.now()));

    return {
        activities,
        activitiesUpdatedAt,
        loadAllActivities,
        importAllActivities,
        newActivity,
        saveActivity,
        deleteActivity
    };
});
