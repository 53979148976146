import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import requestManager from 'src/libs/request-manager';
import { computed, ref } from 'vue';

const taxRatesCacheTTLMins = 5;

export const useTaxRateStore = defineStore('tax-rate', () => {
    const taxRates = ref(new Map());
    const taxRatesLoadedAt = ref(null);
    const taxRatesDirty = ref(false);

    const taxRateLabelFilter = ref('');

    const taxRatesInvalidated = computed(() =>
        taxRatesDirty.value
        || !taxRatesLoadedAt.value
        || DateTime.now().diff(taxRatesLoadedAt.value, [ 'minutes' ]).minutes >= taxRatesCacheTTLMins);

    const inactiveTaxRates = computed(() => Array.from(taxRates.value.values()).filter(tr => !tr.active));
    const activeTaxRates = computed(() => Array.from(taxRates.value.values()).filter(tr => tr.active));
    const hasActiveTaxRates = computed(() => activeTaxRates.value.length > 0);
    const totalActiveTaxRates = computed(() => activeTaxRates.value.length);

    const hasTaxRateFilters = computed(() => taxRateLabelFilter.value.length > 0);
    const hasTaxRateLabelFilter = computed(() => taxRateLabelFilter.value.length > 0);

    const sortedActiveTaxRates = computed(() =>
        activeTaxRates.value.sort((a, b) => {
            if (a.userId === null && b.userId !== null) return -1;
            if (a.userId !== null && b.userId === null) return 1;
            if (a.userId !== b.userId) return a.userId - b.userId;
            return a.label.localeCompare(b.label);
        }));

    const filteredActiveTaxRates = computed(() =>
        sortedActiveTaxRates.value.filter(tr =>
            tr.active
            && (!hasTaxRateFilters.value
                || (hasTaxRateLabelFilter.value && tr.label.toLowerCase().includes(taxRateLabelFilter.value.toLowerCase())))));

    const hasFilteredActiveTaxRates = computed(() => filteredActiveTaxRates.value.length > 0);
    const totalFilteredActiveTaxRates = computed(() => filteredActiveTaxRates.value.length);

    function resetAllFilters() {
        taxRateLabelFilter.value = '';
    }

    function invalidateTaxRates() {
        taxRatesDirty.value = true;
    }

    async function loadTaxRates() {
        if (!taxRatesInvalidated.value) {
            return;
        }
        const resources = (await requestManager.call('get', '/tax-rates')).data;
        for (const r of resources) {
            taxRates.value.set(r.id, r);
        }
        taxRatesLoadedAt.value = DateTime.now();
        taxRatesDirty.value = false;
    }

    async function saveTaxRate(taxRate) {
        if (taxRate.hasOwnProperty('id')) {
            throw new Error('Tax rate update is not supported');
        }
        const resource = (await requestManager.call('post', '/tax-rates', taxRate)).data;
        taxRates.value.set(resource.id, resource);
    }

    async function deleteTaxRate(taxRate) {
        await requestManager.call('delete', `/tax-rates/${taxRate.id}`);
        taxRates.value.delete(taxRate.id);
    }

    return {
        taxRates,
        taxRatesLoadedAt,
        taxRatesDirty,
        taxRateLabelFilter,
        taxRatesInvalidated,
        inactiveTaxRates,
        activeTaxRates,
        hasActiveTaxRates,
        totalActiveTaxRates,
        hasTaxRateFilters,
        hasTaxRateLabelFilter,
        sortedActiveTaxRates,
        filteredActiveTaxRates,
        hasFilteredActiveTaxRates,
        totalFilteredActiveTaxRates,
        resetAllFilters,
        invalidateTaxRates,
        loadTaxRates,
        saveTaxRate,
        deleteTaxRate
    };
});
