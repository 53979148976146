import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePwaStore = defineStore('pwa', () => {
    const isInstallable = ref(false);
    const installCallback = ref(null);

    const installToDevice = () => {
        if (!installCallback.value) {
            return;
        }
        installCallback.value.prompt();
    };

    window.addEventListener('beforeinstallprompt', (e) => {
        isInstallable.value = true;
        installCallback.value = e;
    });

    window.addEventListener('appinstalled', () => {
        isInstallable.value = false;
        installCallback.value = null;
    });

    return {
        isInstallable,
        installToDevice
    };
});
