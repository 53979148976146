import { defineStore } from 'pinia';
import { TaskStatus } from 'src/models/task.js';
import { InvoiceStatus } from 'src/services/invoice-service.js';
import { DateRange, resolveDateRange } from 'src/utils/datetime-util.js';
import { useInvoiceStore } from 'stores/invoice-store.js';
import { useTagStore } from 'stores/tag-store.js';
import { useTaskStore } from 'stores/task-store.js';
import { computed, ref } from 'vue';

export const useReportStore = defineStore('report', () => {
    const invoiceStore = useInvoiceStore();
    const tagStore = useTagStore();
    const taskStore = useTaskStore();

    const filters = ref({
        reportType: 'TaskTimeSummaryReport',
        dateRange: resolveDateRange(DateRange.LAST_14_DAYS),
        tags: []
    });

    const hasDateRangeFilter = computed(() => {
        return filters.value.dateRange && filters.value.dateRange.from !== null && filters.value.dateRange.to !== null;
    });

    const hasTagsFilter = computed(() => {
        return filters.value.tags?.length > 0;
    });

    const filteredInvoices = computed(() => {
        return invoiceStore.invoices.map(inv => {
            inv.client = tagStore.findTagByClientID(inv.clientId);
            return inv;
        }).filter(inv => !(
            inv.status === InvoiceStatus.NEW
            || (hasDateRangeFilter.value && !(inv.invoiceDate >= filters.value.dateRange.from && inv.invoiceDate <= filters.value.dateRange.to))
            || (hasTagsFilter.value && !(filters.value.tags.includes(inv.client)))
        )).sort((invA, invB) => {
            if (invA.invoiceDate > invB.invoiceDate) return -1;
            if (invA.invoiceDate < invB.invoiceDate) return 1;
            return 0;
        });
    });

    const filteredTasks = computed(() => {
        const getValue = (dateTime) => {
            if (dateTime === null) return 0;
            if (dateTime === Infinity) return Infinity;
            return dateTime.toUnixInteger();
        };
        return taskStore.tasks
            .filter(task => {
                return !(!!task.deletedAt
                    || task.status === TaskStatus.NOT_STARTED // Only show tasks which have time logged.
                    || (hasDateRangeFilter.value && !task.hasActivityWithinDateRange(filters.value.dateRange.from, filters.value.dateRange.to))
                    || (hasTagsFilter.value && !task.hasAllTags(filters.value.tags)));
            })
            .sort((taskA, taskB) => {
                if (getValue(taskA?.endDate) > getValue(taskB?.endDate)) return -1;
                if (getValue(taskA?.endDate) < getValue(taskB?.endDate)) return 1;
                return 0;
            });
    });

    const resetAllFilters = () => {
        filters.value = {
            reportType: filters.value.reportType,
            dateRange: resolveDateRange(DateRange.LAST_14_DAYS),
            tags: []
        };
    };

    const addTagToFilter = (tag) => {
        if (filters.value.tags.find(t => t.label === tag.label)) {
            return;
        }
        filters.value.tags.push(tag);
    };

    const removeAllGenericTagsFromFilter = () => {
        filters.value.tags = filters.value.tags.filter(t => t.client);
    };

    return {
        filters,
        hasDateRangeFilter,
        hasTagsFilter,
        filteredInvoices,
        filteredTasks,
        resetAllFilters,
        addTagToFilter,
        removeAllGenericTagsFromFilter
    };
});
