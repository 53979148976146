import Decimal from 'decimal.js';

export const InvoiceStatus = {
    NEW: 'NEW',
    READY: 'READY',
    UNPAID: 'UNPAID',
    OVERDUE: 'OVERDUE',
    PARTIAL_PAID: 'PARTIAL_PAID',
    PAID: 'PAID'
};

export const InvoiceStatusSettings = {
    [InvoiceStatus.NEW]: {
        icon: 'sym_o_pending',
        label: 'New',
        color: 'light-blue-5',
        textColor: 'white'
    },
    [InvoiceStatus.READY]: {
        icon: 'sym_o_preliminary',
        label: 'Ready',
        color: 'green-7',
        textColor: 'white'
    },
    [InvoiceStatus.UNPAID]: {
        icon: 'sym_o_priority_high',
        label: 'Unpaid',
        color: 'orange-6',
        textColor: 'white'
    },
    [InvoiceStatus.OVERDUE]: {
        icon: 'sym_o_notifications_active',
        label: 'Overdue',
        color: 'negative',
        textColor: 'white'
    },
    [InvoiceStatus.PARTIAL_PAID]: {
        icon: 'sym_o_stroke_partial',
        label: 'Partially Paid',
        color: 'amber-10',
        textColor: 'white'
    },
    [InvoiceStatus.PAID]: {
        icon: 'sym_o_check_circle',
        label: 'Paid',
        color: 'positive',
        textColor: 'white'
    }
};

/**
 * Calculates the totals for an invoice item, including sub-total, tax total, and the final total.
 *
 * The function mutates the provided invoiceItem object by adding or updating the 'subTotal', 'taxTotal', and 'total'
 * properties. If the taxRate is not provided, the 'taxRateId' does not match, or the 'taxRateId' is not present, tax
 * calculations are skipped.
 *
 * @param {Object} invoiceItem
 * @param {Object} [taxRate = null]
 * @returns {Object}
 */
export function calculateInvoiceItemTotals(invoiceItem, taxRate = null) {
    const itemIsCalculable = invoiceItem
        && typeof invoiceItem.quantity === 'number'
        && typeof invoiceItem.unitPrice === 'number';
    if (!itemIsCalculable) {
        invoiceItem.subTotal = 0;
        invoiceItem.taxTotal = 0;
        invoiceItem.total = 0;
        return invoiceItem;
    }
    const taxIsApplicable = taxRate && invoiceItem.taxRateId && taxRate.id === invoiceItem.taxRateId;
    const taxRatePercent = taxIsApplicable ? taxRate.ratePercent ?? 0 : 0;
    const subTotal = new Decimal(invoiceItem.unitPrice).times(invoiceItem.quantity);
    const taxTotal = taxRatePercent ? subTotal.times(new Decimal(taxRatePercent).div(100)).toDP(2) : new Decimal(0);
    const total = subTotal.plus(taxTotal).toDP(2);
    invoiceItem.subTotal = total.minus(taxTotal).toNumber();
    invoiceItem.taxTotal = taxTotal.toNumber();
    invoiceItem.total = total.toNumber();
    return invoiceItem;
}

export default class InvoiceService {

    static sortByDescendingInvoiceNumber(invoiceA, invoiceB) {
        if (+invoiceA.invoiceNumber > +invoiceB.invoiceNumber) return -1;
        if (+invoiceA.invoiceNumber < +invoiceB.invoiceNumber) return 1;
        return 0;
    }

    static sortByStatusOverdueAsPriority(invoiceA, invoiceB) {
        if (invoiceA.status === InvoiceStatus.OVERDUE && invoiceB.status !== InvoiceStatus.OVERDUE) return -1;
        if (invoiceA.status !== InvoiceStatus.OVERDUE && invoiceB.status === InvoiceStatus.OVERDUE) return 1;
        return 0;
    }
}
