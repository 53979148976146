import { api } from 'boot/axios.js';

export default class ClientService {

    static async createClient(request) {
        return (await api.post('/clients', request)).data;
    }

    static async getClients() {
        return (await api.get('/clients')).data;
    }

    static async getClient(clientId) {
        return (await api.get(`/clients/${clientId}`)).data;
    }

    static async updateClient(request) {
        return (await api.put(`/clients/${request.id}`, request)).data;
    }

    static async deleteClient(clientId) {
        return (await api.delete(`/clients/${clientId}`)).data;
    }

    static async restoreClient(clientId) {
        return (await api.put(`/bin/clients/${clientId}`)).data;
    }


    static async createClientProject(clientId, request) {
        return (await api.post(`/clients/${clientId}/projects`, {
            label: request.label,
            summary: request.summary
        })).data;
    }

    static async getClientProjects(clientId) {
        return (await api.get(`/clients/${clientId}/projects`)).data;
    }

    static async getClientProject(clientId, projectId) {
        return (await api.get(`/clients/${clientId}/projects/${projectId}`)).data;
    }

    static async updateClientProject(clientId, request) {
        return (await api.put(`/clients/${clientId}/projects/${request.id}`, {
            id: request.id,
            label: request.label,
            summary: request.summary
        })).data;
    }

    static async deleteClientProject(clientId, projectId) {
        return (await api.delete(`/clients/${clientId}/projects/${projectId}`)).data;
    }
}
