import cloneDeep from 'lodash/cloneDeep';
import { createPinia, getActivePinia } from 'pinia';
import { store } from 'quasar/wrappers';

export default store(() => {
    const pinia = createPinia();
    pinia.use(piniaResettableState); // Must be initialized prior to persisted state plugin.
    return pinia;
});

function piniaResettableState({ store }) {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(cloneDeep(initialState));
}

export function resetAllStores() {
    const states = Object.keys(getActivePinia()?.state.value);
    for (const state of states) {
        getActivePinia()._s.get(state).$reset();
    }
}
