import { auth } from 'boot/axios';
import { pusher } from 'boot/pusher';

const subscribedChannels = new Map();
const registeredChannelEvents = new Map();

export function useChannelManager() {

    function channelIsSubscribed(channelName) {
        return subscribedChannels.has(channelName);
    }

    function channelHasRegisteredEvents(channelName) {
        return registeredChannelEvents.has(channelName)
            && registeredChannelEvents.get(channelName).size > 0;
    }

    function channelEventIsRegistered(channelName, eventID, handler = null) {
        return registeredChannelEvents.has(channelName)
            && registeredChannelEvents.get(channelName).has(eventID)
            && (!handler || (handler && registeredChannelEvents.get(channelName).get(eventID).includes(handler)));
    }

    async function subscribeToAvailableChannels() {
        const channels = (await auth.get('/user/channels')).data ?? [];
        for (const c of channels) {
            const channelSubscription = pusher.subscribe(c.id);
            subscribedChannels.set(c.name, channelSubscription);
            registeredChannelEvents.set(c.name, new Map());
        }
    }

    function unsubscribeFromAllChannels() {
        for (const [ channelName, channelSubscription ] of subscribedChannels) {
            unregisterAllChannelEvents(channelName);
            channelSubscription.unsubscribe();
            subscribedChannels.delete(channelName);
        }
    }

    function registerChannelEvent(channelName, eventID, handler) {
        if (!channelIsSubscribed(channelName)) {
            return;
        }
        subscribedChannels.get(channelName).bind(eventID, handler);
        registeredChannelEvents.get(channelName).has(eventID)
            ? registeredChannelEvents.get(channelName).get(eventID).add(handler)
            : registeredChannelEvents.get(channelName).set(eventID, new Set([ handler ]));
    }

    function unregisterChannelEvent(channelName, eventID, handler = null) {
        if (!channelIsSubscribed(channelName) || !channelEventIsRegistered(channelName, eventID, handler)) {
            return;
        }
        if (handler) {
            subscribedChannels.get(channelName).unbind(eventID, handler);
            registeredChannelEvents.get(channelName).get(eventID).delete(handler);
            return;
        }
        subscribedChannels.get(channelName).unbind(eventID);
        registeredChannelEvents.get(channelName).delete(eventID);
    }

    function unregisterAllChannelEvents(channelName) {
        if (!channelIsSubscribed(channelName) || !channelHasRegisteredEvents(channelName)) {
            return;
        }
        subscribedChannels.get(channelName).unbind();
        registeredChannelEvents.get(channelName).clear();
    }

    return {
        channelIsSubscribed,
        channelHasRegisteredEvents,
        channelEventIsRegistered,
        subscribeToAvailableChannels,
        unsubscribeFromAllChannels,
        registerChannelEvent,
        unregisterChannelEvent,
        unregisterAllChannelEvents
    };
}
