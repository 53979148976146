import { auth } from 'boot/axios';
import { Dialog, Loading } from 'quasar';
import { serverEnv } from 'src/config/env';
import { Router } from 'src/router/index.js';
import { RouteName } from 'src/router/routes.js';
import { openUrl } from 'src/services/window-service.js';
import { openComponentDialog } from 'src/utils/quasar-util.js';
import { useAuthStore } from 'stores/auth-store.js';
import { defineAsyncComponent, onMounted } from 'vue';

const loginUrl = serverEnv.authPath;
const githubLoginUrl = loginUrl + '/github';
const gitlabLoginUrl = loginUrl + '/gitlab';
const googleLoginUrl = loginUrl + '/google';
const linkedinLoginUrl = loginUrl + '/linkedin';

export function getProviderProperties() {
    return {
        email: {
            order: 0,
            icon: 'img:/icons/favicon-32x32.png',
            label: 'Email',
            onClick: () => openEmailLoginDialog()
        },
        github: {
            order: 2,
            icon: 'img:/icons/brand-logo_github.svg',
            iconDark: 'img:/icons/brand-logo_github_white.svg',
            label: 'GitHub',
            onClick: (autoReturnRoute) => loginWithSocialAccount(githubLoginUrl, autoReturnRoute)
        },
        gitlab: {
            order: 3,
            icon: 'img:/icons/brand-logo_gitlab.svg',
            label: 'GitLab',
            onClick: (autoReturnRoute) => loginWithSocialAccount(gitlabLoginUrl, autoReturnRoute)
        },
        google: {
            order: 1,
            icon: 'img:/icons/brand-logo_google.svg',
            label: 'Google',
            onClick: (autoReturnRoute) => loginWithSocialAccount(googleLoginUrl, autoReturnRoute)
        },
        linkedin: {
            order: 5,
            icon: 'img:/icons/brand-logo_linkedin.svg',
            label: 'LinkedIn',
            onClick: (autoReturnRoute) => loginWithSocialAccount(linkedinLoginUrl, autoReturnRoute)
        }
    };
}

export function requireAuthenticated(options = {}) {
    options.returnRoute ??= RouteName.VIEW_TASK_LIST;
    options.headline ??= 'Unlock the full potential of time tracking!';
    options.message ??= null;
    if (useAuthStore().isAuthenticated) return;
    onMounted(() => {
        openComponentDialog(defineAsyncComponent(() => import('components/UserLoginDialog.vue')), {
            headline: options.headline,
            message: options.message
        }).onDismiss(() => {
            Router.push({ name: options.returnRoute });
        });
    });
}

export function openUserSubscribeDialog() {
    openComponentDialog(defineAsyncComponent(() => import('components/UserSubscribeDialog.vue')))
}

const loginWithSocialAccount = (url, autoReturnRoute = true) => {
    if (autoReturnRoute) {
        const { name, params } = Router.currentRoute;
        useAuthStore().returnRoute = { name, params };
    }
    openLogin(url, () => Loading.show(), () => Loading.hide());
};

export function openLogin(url) {
    Loading.show();
    const loginWindow = openUrl(url, {
        beforeload: (params, callback) => {
            if (params.url.includes('login_success') || params.url.includes('login_fail')) {
                useAuthStore().loadUser();
                loginWindow.close();
                return;
            }
            callback(params.url);
        },
        exit: () => {
            Loading.hide();
        }
    });
}

export function openEmailLoginDialog() {
    return Dialog.create({ component: defineAsyncComponent(() => import('components/EmailLoginDialog.vue')) });
}

export function openOTPDialog(email, authRequestJWT) {
    return Dialog.create({
        component: defineAsyncComponent(() => import('components/OTPDialog.vue')),
        componentProps: { email, authRequestJWT }
    });
}

export function openLogout() {
    Dialog.create({
        component: defineAsyncComponent(() => import('components/UserLogoutDialog.vue')),
        componentProps: { persistent: true }
    });
}

export function createBasicAuthToken(username, password) {
    return 'Basic ' + btoa(`${username}:${password}`);
}

export async function getProviders() {
    return (await auth.get('/providers')).data;
}

export async function getUser() {
    return (await auth.get('/user')).data;
}

export async function logoutUser() {
    await auth.get('/logout');
}

export async function getUserAccounts() {
    return (await auth.get('/user/accounts')).data;
}

export async function deleteUserAccount(accountId) {
    await auth.delete(`/user/accounts/${accountId}`);
}

export async function requestEmailCode() {
    await auth.post('/user/request-email-code');
}
