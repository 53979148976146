import { RequestManager } from '@jaseeey/request-manager';
import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { resolveDateRange } from 'src/utils/datetime-util';
import { useTaskStore } from 'stores/task-store';
import { computed, ref, unref } from 'vue';

export const useSettingsStore = defineStore('settings', () => {
    const settings = ref({
        brand: {},
        business: {},
        locale: {},
        payment: {}
    });

    const _settingsInvalidatedAt = ref();
    const _settingsLoadedAt = ref();

    const brandSettings = computed(() => unref(settings)?.brand);
    const businessSettings = computed(() => unref(settings)?.business);
    const localeSettings = computed(() => unref(settings)?.locale);
    const paymentSettings = computed(() => unref(settings)?.payment);

    const _areSettingsInvalidated = computed(() => {
        return !!_settingsInvalidatedAt.value || !_settingsLoadedAt.value || DateTime.now().diff(_settingsLoadedAt.value, [ 'minutes' ]).minutes >= 5;
    });

    function invalidateSettings() {
        _settingsInvalidatedAt.value = DateTime.now();
    }

    async function loadAllSettings() {
        if (!_areSettingsInvalidated.value) {
            return;
        }
        await RequestManager.call(api, 'get', '/settings', {}, {}, ({ data }) => {
            settings.value = data;
            syncLocaleSettingsToStores();
        });
    }

    function mergeSettings(_settings) {
        if (!settings.value) {
            settings.value = {};
        }
        Object.assign(settings.value, _settings);
    }

    function prepareBrandSettingsFormData(formData, attrs) {
        // Destructure brand settings to separate logo for multipart upload.
        const { brand: { logo, ...brand } } = attrs;
        if (logo && typeof logo === 'object') {
            formData.append('logo', logo, logo.name);
        }
        else {
            brand.logo = logo;
        }
        formData.append('brand', JSON.stringify(brand));
        return formData;
    }

    async function saveAllSettings(attrs) {
        const formData = new FormData();
        prepareBrandSettingsFormData(formData, attrs);
        formData.append('business', JSON.stringify(attrs.business));
        formData.append('locale', JSON.stringify(attrs.locale));
        formData.append('payment', JSON.stringify(attrs.payment));
        settings.value = (await api.put('/settings', formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data;
    }

    function syncLocaleSettingsToStores() {
        if (settings.value.locale?.taskFilterDateRange) {
            useTaskStore().filters.dateRange = resolveDateRange(localeSettings.value.taskFilterDateRange, undefined, {
                fortnightStartDate: localeSettings.value.fortnightStartDate
            });
        }
    }

    return {
        brandSettings,
        businessSettings,
        localeSettings,
        paymentSettings,
        invalidateSettings,
        mergeSettings,
        loadAllSettings,
        saveAllSettings,
        syncLocaleSettingsToStores
    };
});
