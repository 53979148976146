import axios from 'axios';
import { $event } from 'boot/emitter';
import { boot } from 'quasar/wrappers';
import { serverEnv } from 'src/config/env';
import { Router } from 'src/router/index.js';
import { RouteName } from 'src/router/routes.js';
import { generateUUID } from 'src/utils/crypto-util';
import { notifyError } from 'src/utils/quasar-util';
import { useAppStore } from 'stores/app-store';
import { useAuthStore } from 'stores/auth-store.js';

// Generate a source ID which is attached to requests to identify which client the request originated from. This is
// primarily used to ignore updates from websocket channels where the request initiated from the same client.
export const axiosSourceID = generateUUID();

export const api = axios.create({
    baseURL: serverEnv.apiPath,
    withCredentials: true
});

export const auth = axios.create({
    baseURL: serverEnv.authPath,
    withCredentials: true
});

export default boot(({ app }) => {
    auth.interceptors.response.use(
        res => {
            return res;
        },
        err => {
            if (err?.response?.status === 401) {
                $event.dispatchEvent(new Event('session:expired'));
            }
            return Promise.reject(err);
        }
    );
    api.interceptors.request.use(
        async config => {
            config.headers['X-Source-ID'] = axiosSourceID;
            return config;
        },
        err => {
            return Promise.reject(err);
        }
    );
    api.interceptors.response.use(
        res => {
            return res;
        },
        err => {
            const authStore = useAuthStore();
            if (!err.response || err.response?.status && [ 502, 503, 504, 521 ].includes(err.response.status)) {
                useAppStore().serverOffline = true;
            }
            else if (err.response.status === 401 && authStore.isAuthenticated) {
                $event.dispatchEvent(new Event('session:logout'));
                notifyError('You have been logged out of your session, please login again.');
                Router.push({ name: RouteName.START });
            }
            else if (err.response.status === 401) {
                $event.dispatchEvent(new Event('session:expired'));
            }
            return Promise.reject(err);
        }
    );
    app.config.globalProperties.$axios = axios;
    app.config.globalProperties.$api = api;
    app.config.globalProperties.$auth = auth;
    app.provide('$axios', axios);
    app.provide('$api', api);
    app.provide('$auth', auth);
});
