import { Dialog, Loading, Notify } from 'quasar';

export function openConfirmationDialog(options = {}) {
    return Dialog.create({
        ok: {
            label: 'OK',
            color: 'primary',
            unelevated: true
        },
        cancel: true,
        focus: 'none',
        ...options
    });
}

export function openComponentDialog(component, componentProps = {}) {
    return Dialog.create({ component, componentProps });
}

export function notifySuccess(message, options = {}) {
    return Notify.create({
        type: 'positive',
        progress: true,
        message,
        ...options
    });
}

export function notifyError(message, options = {}) {
    return Notify.create({
        type: 'negative',
        progress: true,
        message,
        ...options
    });
}

export function showLoading() {
    return Loading.show();
}

export function hideLoading() {
    return Loading.hide();
}
