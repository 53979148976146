/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {QSpinnerDots,Dialog,Loading,Meta,Notify} from 'quasar'



const userOptions = { config: {"loading":{"spinner":"QSpinnerDots"},"brand":{"primary":"#004afe","secondary":"#616161","negative":"#f44336","accent":"#673ab7"}},components: {QSpinnerDots},plugins: {Dialog,Loading,Meta,Notify} }
  
userOptions.config.loading.spinner = QSpinnerDots
  
  
export default userOptions

