import { api } from 'boot/axios.js';
import { defineStore } from 'pinia';
import { createBasicAuthToken } from 'src/services/auth-service.js';
import { ref } from 'vue';

export const usePublicInvoiceStore = defineStore('public-invoice', () => {
    const currentInvoice = ref(null);

    const loadPublicInvoice = async (token, recipientEmail = null) => {
        const options = !recipientEmail ? {} : { headers: { 'Authorization': createBasicAuthToken(recipientEmail, token) } };
        const response = await api.get(`/public/invoices/${token}`, options);
        currentInvoice.value = response.data;
        sortInvoiceItems(currentInvoice.value);
        return currentInvoice.value;
    };

    const sortInvoiceItems = invoice => {
        invoice.items.sort((a, b) => {
            if (a.displayOrder > b.displayOrder) return 1;
            if (a.displayOrder < b.displayOrder) return -1;
            return 0;
        });
    };

    return {
        currentInvoice,
        loadPublicInvoice
    };
});
