import { ref, watch } from 'vue';

/**
 * Provides functionality to interact with localStorage, including serialization and deserialization for complex types.
 *
 * @returns {Object} Object containing methods to interact with localStorage.
 */
export function useLocalStorageManager() {

    const appIsRunningAsClient = process.env.CLIENT;

    /**
     * Checks if a value is of a native JavaScript type.
     *
     * @param {*} value - The value to check.
     * @returns {boolean} True if the value is a native type, false otherwise.
     */
    function typeIsNative(value) {
        return [ 'string', 'number', 'boolean' ].includes(typeof value)
            || Array.isArray(value)
            || value === null
            || value instanceof Object
            && value.constructor === Object;
    }

    /**
     * Serializes a value for storage in localStorage.
     *
     * @param {*} value - The value to serialize.
     * @returns {Array} A serialized representation of the value.
     */
    function serialize(value) {
        if (typeIsNative(value)) {
            return [ value ];
        }
        if (value instanceof Map) {
            return [ 'Map', Array.from(value.entries()) ];
        }
        if (value instanceof Set) {
            return [ 'Set', Array.from(value) ];
        }
        return [ value ];
    }

    /**
     * Deserializes a value from its serialized array form.
     *
     * @param {Array} serialized - The serialized representation of a value.
     * @returns {*} The deserialized value.
     */
    function deserialize(serialized) {
        if (serialized.length === 1) {
            return serialized[0];
        }
        const [ type, value ] = serialized;
        switch (type) {
            case 'Map':
                return new Map(value);
            case 'Set':
                return new Set(value);
            default:
                return value;
        }
    }

    /**
     * Sets a value in localStorage.
     *
     * @param {string} key - The key under which to store the value.
     * @param {*} value - The value to store.
     */
    function set(key, value) {
        if (!appIsRunningAsClient) {
            console.warn('Cannot set to local storage; only available in the browser');
            return;
        }
        try {
            localStorage.setItem(key, JSON.stringify(serialize(value)));
        }
        catch (err) {
            console.error('Could not set item to local storage', err);
        }
    }

    /**
     * Retrieves a value from localStorage.
     *
     * @param {string} key - The key of the value to retrieve.
     * @returns {*} The retrieved value, or null if not found.
     */
    function get(key) {
        if (!appIsRunningAsClient) {
            console.warn('Cannot get from local storage; only available in the browser');
            return;
        }
        try {
            const storedValue = localStorage.getItem(key);
            if (!storedValue) {
                return null;
            }
            const parsedValue = JSON.parse(storedValue);
            return deserialize(parsedValue);
        }
        catch (err) {
            console.error('Could not get item from local storage', err);
            return null;
        }
    }

    /**
     * Removes a value from localStorage.
     *
     * @param {string} key - The key of the value to remove.
     */
    function remove(key) {
        if (!appIsRunningAsClient) {
            console.warn('Cannot remove from local storage; only available in the browser');
            return;
        }
        try {
            localStorage.removeItem(key);
        }
        catch (err) {
            console.error('Could not remove item from local storage', err);
        }
    }

    /**
     * Checks if a key exists in localStorage.
     *
     * @param {string} key - The key to check.
     * @returns {boolean} True if the key exists, false otherwise.
     */
    function has(key) {
        return get(key) !== null;
    }

    /**
     * Creates a reactive Vue ref that is synced with a localStorage value. If running on the client-side, it
     * automatically updates localStorage when the ref changes. If running on the server-side, it simply returns the ref
     * without watching it.
     *
     * @param {string} key - The key under which to store the value in localStorage.
     * @param {*} defaultValue - The default value to use if the key is not in localStorage.
     * @returns {Ref<any>} A Vue ref that is synced with the localStorage value or just a simple ref on the server.
     */
    function storeRef(key, defaultValue) {
        const storedValue = appIsRunningAsClient
            ? get(key)
            : null;
        const data = ref(storedValue !== null ? storedValue : defaultValue);
        if (appIsRunningAsClient) {
            watch(data, (newValue) => {
                if (newValue === defaultValue && has(key)) {
                    return remove(key);
                }
                set(key, newValue);
            }, { deep: true });
        }
        return data;
    }

    return { set, get, remove, has, storeRef };
}
