import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { useAuthStore } from 'stores/auth-store.js';
import { ref } from 'vue';

export const useGeoStore = defineStore('geo', () => {

    const _countriesLoadedAt = ref(null);
    const _countriesDirty = ref(false);
    const countries = ref([]);

    const countriesInvalidated = () => {
        // No need for a refresh TTL here, the countries won't change very often.
        return _countriesDirty.value || !_countriesLoadedAt.value;
    };

    const loadAllCountries = async () => {
        if (!useAuthStore().isAuthenticated || !countriesInvalidated()) {
            return;
        }
        const response = await api.get('/geo/countries');
        countries.value = response.data;
        _countriesDirty.value = false;
        _countriesLoadedAt.value = DateTime.now();
    };

    return {
        _countriesLoadedAt,
        _countriesDirty,
        countries,
        countriesInvalidated,
        loadAllCountries
    };
});
