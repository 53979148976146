const baseUrl = process.env.SERVER_BASE_URL ?? '';

export const pusherEnv = Object.freeze({
    key: process.env.PUSHER_KEY,
    cluster: process.env.PUSHER_CLUSTER
});

export const recaptchaEnv = Object.freeze({
    siteKey: process.env.RECAPTCHA_SITE_KEY
});

export const sentryEnv = Object.freeze({
    dsn: process.env.SENTRY_DSN,
    tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE ? Number(process.env.SENTRY_TRACES_SAMPLE_RATE) : 0,
    replaysSessionSampleRate: process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE ? Number(process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE) : 0,
    replaysOnErrorSampleRate: process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ? Number(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) : 0
});

export const serverEnv = Object.freeze({
    baseUrl,
    authPath: baseUrl + (process.env.SERVER_AUTH_PATH ?? '/auth'),
    apiPath: baseUrl + (process.env.SERVER_API_PATH ?? '/api')
});

export const umamiEnv = Object.freeze({
    websiteID: process.env.UMAMI_WEBSITE_ID
});
