/**
 * Generates a random UUID (Universally Unique Identifier) in the format of a version 4 UUID. The UUID is generated
 * using random numbers which are then formatted according to the UUID v4 specification.
 *
 * @returns {string} A randomly generated UUID v4 string.
 */
export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
