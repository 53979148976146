export default class TagService {

    static sortTags(tags) {
        const compareIsGlobal = (tagA, tagB) => {
            if (tagA.isGlobal && !tagB.isGlobal) return -1;
            if (!tagA.isGlobal && tagB.isGlobal) return 1;
            return 0;
        };
        const compareClient = (tagA, tagB) => {
            if (tagA.client && !tagB.client) return -1;
            if (!tagA.client && tagB.client) return 1;
            return 0;
        };
        const compareLabel = (tagA, tagB) => {
            if (tagA.label.toLowerCase() < tagB.label.toLowerCase()) return -1;
            if (tagA.label.toLowerCase() > tagB.label.toLowerCase()) return 1;
            return 0;
        };
        return tags.sort((tagA, tagB) => compareClient(tagA, tagB) || compareIsGlobal(tagA, tagB) || compareLabel(tagA, tagB));
    }
}
