import { joinURLPaths } from 'src/utils/router-util';
import { useAuthStore } from 'stores/auth-store.js';

export const RouteName = {
    START: 'Start',
    MANAGE_ACCOUNT: 'ManageAccount',
    MANAGE_AUTH: 'ManageAuth',
    MANAGE_CLIENT: 'ManageClient',
    MANAGE_INVOICE: 'ManageInvoice',
    MANAGE_SETTINGS: 'ManageSettings',
    MANAGE_TASK: 'ManageTask',
    MANAGE_TAX_RATES: 'ManageTaxRates',
    NEW_CLIENT: 'NewClient',
    NEW_INVOICE: 'NewInvoice',
    NEW_TASK: 'NewTask',
    VIEW_CLIENT_LIST: 'ViewClientList',
    VIEW_INVOICE_LIST: 'ViewInvoiceList',
    VIEW_NOTES_LIST: 'ViewNotesList',
    VIEW_PUBLIC_INVOICE: 'ViewPublicInvoice',
    VIEW_RULES_LIST: 'ViewRulesList',
    VIEW_TAG_LIST: 'ViewTagList',
    VIEW_TASK_LIST: 'ViewTaskList',
    VIEW_REPORT_DASHBOARD: 'ViewReportDashboard',
    LOGOUT: 'Logout'
};

// Define any routes by RouteName which do not require authorization.
export const publicRoutes = [
    RouteName.START,
    RouteName.VIEW_PUBLIC_INVOICE
];

export const publicOnlyRoutes = [
    RouteName.START
];

const routes = [
    {
        path: '/start',
        component: () => import('layouts/SplashLayout.vue'),
        children: [
            {
                path: '',
                name: RouteName.START,
                component: () => import('pages/Start.vue')
            }
        ]
    }, {
        path: '/inv/:invoiceToken',
        component: () => import('layouts/BaseLayout.vue'),
        children: [
            {
                path: '',
                name: RouteName.VIEW_PUBLIC_INVOICE,
                component: () => import('pages/ViewPublicInvoice.vue')
            }
        ]
    }, {
        path: '/',
        component: () => import('layouts/MainLayout.vue'),
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            const query = { ...(to?.redirectedFrom?.query ?? {}), ...(to?.query ?? {}) };
            if (to.path === '/' && !authStore.isAuthenticated) {
                return next({ name: RouteName.START, query });
            }
            if (to.path === '/') {
                return next({ name: RouteName.VIEW_TASK_LIST, query });
            }
            next();
        },
        children: [
            {
                path: 'account/auth',
                name: RouteName.MANAGE_AUTH,
                component: () => import('pages/ManageAuth.vue')
            },
            {
                path: 'account',
                name: RouteName.MANAGE_ACCOUNT,
                component: () => import('pages/ManageAccount.vue')
            },
            {
                path: 'clients',
                children: [
                    {
                        path: '',
                        name: RouteName.VIEW_CLIENT_LIST,
                        component: () => import('pages/ViewClientList.vue')
                    },
                    {
                        path: 'new',
                        name: RouteName.NEW_CLIENT,
                        component: () => import('pages/NewClient.vue')
                    },
                    {
                        path: ':clientId',
                        name: RouteName.MANAGE_CLIENT,
                        meta: {
                            maskedTitle: 'Client',
                            maskedURL: joinURLPaths(process.env.VUE_ROUTER_BASE, 'clients/:clientID')
                        },
                        props: function (route) {
                            route.params.clientId = parseInt(route.params.clientId);
                            return route;
                        },
                        component: () => import('pages/ManageClient.vue')
                    }
                ]
            },
            {
                path: 'invoices',
                children: [
                    {
                        path: '',
                        name: RouteName.VIEW_INVOICE_LIST,
                        component: () => import('pages/ViewInvoiceList.vue')
                    },
                    {
                        path: 'new',
                        name: RouteName.NEW_INVOICE,
                        component: () => import('pages/NewInvoice.vue')
                    },
                    {
                        path: ':invoiceId',
                        name: RouteName.MANAGE_INVOICE,
                        meta: {
                            maskedTitle: 'Invoice',
                            maskedURL: joinURLPaths(process.env.VUE_ROUTER_BASE, 'invoices/:invoiceID')
                        },
                        props(route) {
                            route.params.invoiceId = parseInt(route.params.invoiceId);
                            return route;
                        },
                        component: () => import('pages/ManageInvoice.vue')
                    }
                ]
            },
            {
                path: 'notes',
                name: RouteName.VIEW_NOTES_LIST,
                component: () => import('pages/ViewNotesList.vue')
            },
            {
                path: 'reports',
                name: RouteName.VIEW_REPORT_DASHBOARD,
                component: () => import('pages/ViewReportDashboard.vue')
            },
            {
                path: 'rules',
                name: RouteName.VIEW_RULES_LIST,
                component: () => import('pages/ViewRulesList.vue')
            },
            {
                path: 'settings',
                name: RouteName.MANAGE_SETTINGS,
                component: () => import('pages/ManageSettings.vue')
            },
            {
                path: 'tags',
                name: RouteName.VIEW_TAG_LIST,
                component: () => import('pages/ViewTagList.vue')
            },
            {
                path: 'tasks',
                children: [
                    {
                        path: '',
                        name: RouteName.VIEW_TASK_LIST,
                        component: () => import('pages/ViewTaskList.vue')
                    },
                    {
                        path: 'new',
                        name: RouteName.NEW_TASK,
                        component: () => import('pages/NewTask.vue')
                    },
                    {
                        path: ':taskId',
                        name: RouteName.MANAGE_TASK,
                        meta: {
                            maskedTitle: 'Task',
                            maskedURL: joinURLPaths(process.env.VUE_ROUTER_BASE, 'tasks/:taskID')
                        },
                        props(route) {
                            route.params.taskId = parseInt(route.params.taskId);
                            return route;
                        },
                        component: () => import('pages/ManageTask.vue')
                    }
                ]
            },
            {
                path: 'tax-rates',
                name: RouteName.MANAGE_TAX_RATES,
                component: () => import('pages/ManageTaxRates.vue')
            }
        ]
    },
    {
        path: '/:catchAll(.*)*',
        component: () => import('pages/ErrorNotFound.vue')
    }
];

export default routes;
