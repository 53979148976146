import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { useLocalStorageManager } from 'src/composables/local-storage-manager';
import { computed, ref } from 'vue';

export const useNotificationStore = defineStore('notification', () => {
    const { storeRef } = useLocalStorageManager();

    const _notifications = ref({});
    const _notificationsAcknowledged = storeRef('notification/notificationsAcknowledged', []);
    const _notificationsDirty = ref(false);
    const _notificationsLoadedAt = ref(null);

    const notifications = computed(() => Array.from(_notifications.value.values()));

    function notificationsInvalidated() {
        return _notificationsDirty.value
            || !_notificationsLoadedAt.value
            || DateTime.now().diff(_notificationsLoadedAt.value, [ 'minutes' ]).minutes >= 5;
    }

    function invalidateNotifications() {
        _notificationsDirty.value = true;
    }

    async function loadAllNotifications() {
        const notifications = (await api.get('/notifications')).data;
        _notifications.value = {};
        for (const n of notifications) {
            _notifications.value[n.id] = n;
        }
        // Remove any acknowledged notifications which no longer exist, no need to store them any longer.
        for (const n of _notificationsAcknowledged.value) {
            if (Object.keys(_notifications.value).includes(n.toString())) continue;
            _notificationsAcknowledged.value.splice(_notificationsAcknowledged.value.indexOf(n), 1);
        }
        _notificationsDirty.value = false;
        _notificationsLoadedAt.value = DateTime.now();
        return _notifications;
    }

    function getNewNotifications() {
        return Object.values(_notifications.value).filter(n => !_notificationsAcknowledged.value.includes(n.id));
    }

    function markNotificationAsSeen(notification) {
        _notificationsAcknowledged.value.push(notification.id);
    }

    return {
        _notifications,
        _notificationsAcknowledged,
        _notificationsDirty,
        _notificationsLoadedAt,
        notifications,
        notificationsInvalidated,
        invalidateNotifications,
        loadAllNotifications,
        getNewNotifications,
        markNotificationAsSeen
    };
});
