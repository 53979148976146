/**
 * @typedef ObservableMapKey
 * @type {string|number}
 */

/**
 * ObservableMap extends the native Map object to notify listeners about changes. It allows subscribing to and
 * unsubscribing from change notifications, and automatically notifies subscribers when changes occur.
 */
export class ObservableMap extends Map {

    /**
     * Creates an instance of ObservableMap.
     * @param {Iterable} [entries]
     */
    constructor(entries = []) {
        super(entries);
        this.listeners = new Set();
        this.changedKeys = new Set();
        this.notifyScheduled = false;
    }

    /**
     * Subscribes a listener to change notifications.
     *
     * @param {Function} handler
     */
    subscribe(handler) {
        this.listeners.add(handler);
    }

    /**
     * Unsubscribes a listener from change notifications.
     *
     * @param {Function} handler
     */
    unsubscribe(handler) {
        this.listeners.delete(handler);
    }

    /**
     * Schedules a notification to all subscribed listeners. This method ensures that notifications are batched and
     * only sent once per event loop tick.
     */
    scheduleNotify() {
        if (!this.notifyScheduled) {
            this.notifyScheduled = true;
            setTimeout(() => {
                this.notify();
            }, 0);
        }
    }

    /**
     * Notifies all subscribed listeners of changes. Each listener is called with the type of update and the key of the
     * changed item.
     */
    notify() {
        this.notifyScheduled = false;
        for (const key of this.changedKeys) {
            for (const listener of this.listeners) {
                listener('update', key);
            }
        }
        this.changedKeys.clear();
    }
    /**
     * Sets the value for the key in the ObservableMap object. Notifies subscribers of the change.
     *
     * @param {ObservableMapKey} key
     * @param {any} value
     * @returns {ObservableMap}
     */
    set(key, value) {
        const wrappedValue = this.wrapValue(key, value);
        super.set(key, wrappedValue);
        this.changedKeys.add(key);
        this.scheduleNotify();
        return this;
    }

    /**
     * Wraps a value with a proxy if it's an object, allowing for deep observation of changes.
     *
     * @param {ObservableMapKey} key
     * @param {any} value
     * @returns {any}
     */
    wrapValue(key, value) {
        if (value !== null && typeof value === 'object') {
            const map = this;
            return new Proxy(value, {
                set(target, prop, val) {
                    target[prop] = val;
                    map.changedKeys.add(key);
                    map.scheduleNotify();
                    return true;
                },
                deleteProperty(target, prop) {
                    if (delete target[prop]) {
                        map.changedKeys.add(key);
                        map.scheduleNotify();
                        return true;
                    }
                    return false;
                }
            });
        }
        return value;
    }

    /**
     * Deletes a key-value pair from the ObservableMap. Notifies subscribers of the change.
     *
     * @param {ObservableMapKey} key
     * @returns {boolean}
     */
    delete(key) {
        if (super.delete(key)) {
            this.changedKeys.add(key);
            this.scheduleNotify();
            return true;
        }
        return false;
    }

    /**
     * Removes all key-value pairs from the ObservableMap. Notifies subscribers of the change.
     */
    clear() {
        for (const key of this.keys()) {
            this.changedKeys.add(key);
        }
        super.clear();
        this.scheduleNotify();
    }
}
