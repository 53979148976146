import { Duration } from 'luxon';
import { defineStore } from 'pinia';
import { useActivityStore } from 'stores/activity-store';
import { useTaskStore } from 'stores/task-store.js';
import { computed, ref, watch } from 'vue';

const workerPath = new URL('../workers/timer-worker.js', import.meta.url);

export const useTimerStore = defineStore('timer', () => {
    const activityStore = useActivityStore();
    const taskStore = useTaskStore();

    const legacyTimer = ref(null);
    const worker = ref(null);
    const duration = ref(null);

    const activeTaskActivitiesUpdatedAt = computed(() => {
        const activeActivity = taskStore.activeActivity;
        const activeTaskID = activeActivity?.taskId;
        const activitiesLastUpdatedAt = activityStore.activitiesUpdatedAt;
        if (!activeTaskID) {
            return null;
        }
        return activitiesLastUpdatedAt.get(activeTaskID);
    });

    function startTimer() {
        if (!taskStore.activeTask?.activeActivity) {
            return;
        }
        // The total duration of all activities should be the initial duration value to prevent the UI from showing odd
        // values to the user, but only the completed duration needs to be passed to the timer because the active duration
        // is what is being calculated.
        const completedDuration = taskStore.activeTask.getCompletedDuration([ 'seconds' ]);
        const activeDuration = taskStore.activeTask.getActiveDuration([ 'seconds' ]);
        duration.value = completedDuration.plus(activeDuration);
        _workerIsSupported()
            ? _startWorker(completedDuration.seconds, taskStore.activeTask.activeActivity.startDate.toISO())
            : _startLegacyTimer(completedDuration.seconds, taskStore.activeTask.activeActivity.startDate.toISO());
        console.debug('Timer started');
    }

    function stopTimer() {
        if (!legacyTimer.value && !worker.value) {
            return;
        }
        legacyTimer.value
            ? _stopLegacyTimer()
            : _stopWorker();
        duration.value = null;
        console.debug('Timer stopped');
    }

    function _workerIsSupported() {
        return !!window.Worker;
    }

    function _startWorker(completedDurationSecs, startDate) {
        if (worker.value) {
            _stopWorker();
        }
        worker.value = new Worker(workerPath);
        worker.value.onmessage = (e) => duration.value = Duration.fromObject(e.data);
        worker.value.postMessage({ command: 'INIT', params: [ completedDurationSecs, startDate ] });
    }

    function _stopWorker() {
        if (!worker.value) {
            return;
        }
        worker.value.terminate();
        worker.value = null;
    }

    function _startLegacyTimer(completedDurationSecs, startDate) {
        if (legacyTimer.value) {
            _stopLegacyTimer();
        }
        import(workerPath.toString()).then(() => {
            const cb = (seconds) => duration.value = Duration.fromObject({ seconds });
            legacyTimer.value = window.Timer;
            legacyTimer.value.init(completedDurationSecs, startDate, cb);
        });
    }

    function _stopLegacyTimer() {
        if (!legacyTimer.value) {
            return;
        }
        legacyTimer.value.terminate();
        legacyTimer.value = null;
    }

    function _watchActiveTaskChanges() {
        watch(activeTaskActivitiesUpdatedAt, (newVal = null, oldVal = null) => {
            // TODO Rather than stopping and starting the timer for each change, it might be worth implementing a
            //   mechanism to update the data within the worker to reduce some overhead.
            if (!newVal && oldVal || newVal !== oldVal) {
                console.debug('Task activity changes detected...');
            }
            if (!newVal) {
                stopTimer();
            }
            if (newVal) {
                startTimer();
            }
        }, { immediate: true });
    }

    _watchActiveTaskChanges();

    return {
        worker,
        duration,
        startTimer,
        stopTimer
    };
});
