import { RequestManager } from '@jaseeey/request-manager';
import { api } from 'boot/axios';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import TagService from 'src/services/tag-service.js';
import { useAuthStore } from 'stores/auth-store.js';
import { useTaskStore } from 'stores/task-store.js';

/**
 * @typedef TaskStoreType
 * @type { actions | getters | state | import('pinia').Store }
 */

/**
 * @typedef useTagStore
 * @type function
 * @param {import('pinia').Pinia | null | undefined} [pinia]
 * @param {import('pinia').StoreGeneric} [hot]
 * @returns TaskStoreType
 */

/**
 * @type useTagStore
 */
export const useTagStore = defineStore('tag', {
    state: () => ({
        tagsDirty: false,
        tagsLoadedAt: null,
        tags: [],
        filters: {
            label: ''
        }
    }),
    getters: {
        hasFilters() {
            return this.filters.label.length > 0;
        },

        hasLabelFilter() {
            return !!this.filters.label;
        },

        totalTags() {
            return this.tags.length;
        },

        hasTags() {
            return this.tags.length > 0;
        },

        hasGenericTags() {
            return this.tags.filter(t => !t.client).length > 0;
        },

        totalGenericTags() {
            return this.tags.filter(t => !t.client).length;
        },

        sortedTags() {
            return TagService.sortTags(this.tags);
        },

        filteredTags() {
            return this.sortedTags
                .filter(t => !t.client)
                .filter(t => !this.hasFilters || (this.hasLabelFilter && t.label.toLowerCase().includes(this.filters.label.toLowerCase())));
        },

        totalFilteredTags() {
            return this.filteredTags.length;
        },

        findTagByClientID(state) {
            const clientTagMap = state.tags.reduce((acc, t) => {
                if (t.client) acc[t.client.id] = t;
                return acc;
            }, {});
            return (clientID) => clientTagMap?.[clientID];
        },

        findClientByID(state) {
            const clientTagMap = state.tags.reduce((acc, t) => {
                if (t.client) acc[t.client.id] = t;
                return acc;
            }, {});
            // The client is represented in the reverse order, this should be fixed in the future so everything is
            // a tag. This is just a temporary fix until that happens.
            return (clientID) => {
                const { client, ...tag } = clientTagMap?.[clientID];
                return { ...client, tag, label: tag.label };
            };
        }
    },
    actions: {
        resetAllFilters() {
            this.filters.label = '';
        },

        tagsInvalidated() {
            return this.tagsDirty
                || !this.tagsLoadedAt
                || DateTime.now().diff(this.tagsLoadedAt, [ 'minutes' ]).minutes >= 5;
        },

        invalidateTags() {
            this.tagsDirty = true;
        },

        appendTags(tags) {
            for (const tag of tags) {
                const existingTag = this.tags.find(t => t.id === tag.id);
                if (existingTag) {
                    Object.assign(existingTag, tag);
                    continue;
                }
                this.tags.push(tag);
            }
        },

        mergeTag(tag) {
            const existingTag = this.tags.find(t => t.id === tag.id);
            if (existingTag) {
                Object.assign(existingTag, tag);
                return;
            }
            this.tags.push(tag);
        },

        mergeTags(tags) {
            for (const tag of tags) {
                const existingTag = this.tags.find(t => t.id === tag.id);
                if (existingTag) {
                    Object.assign(existingTag, tag);
                    continue;
                }
                this.tags.push(tag);
            }
            for (const tag of this.tags) {
                const existingTag = tags.find(t => t.id === tag.id);
                if (!existingTag) {
                    this.tags.splice(this.tags.indexOf(tag), 1);
                    useTaskStore().removeTagFromAllTasks(tag);
                }
            }
        },

        createEmptyGenericTag() {
            return { id: null, createdAt: null, updatedAt: null, label: null };
        },

        createEmptyClientTag() {
            const genericTag = this.createEmptyGenericTag();
            return { ...genericTag, client: { contactName: null, contactEmail: null, companyName: null } };
        },

        async loadTags() {
            if (!useAuthStore().isAuthenticated || !this.tagsInvalidated()) {
                return;
            }
            await RequestManager.call(api, 'get', '/tags', {}, {}, res => {
                const loadedTags = res.data;
                this.mergeTags(loadedTags);
                this.tagsDirty = false;
                this.tagsLoadedAt = DateTime.now();
            });
        },

        async deleteTag(tag) {
            try {
                if (tag.createdAt) {
                    await api.delete(`/tags/${tag.id}`);
                }
                this.tags.splice(this.tags.indexOf(tag), 1);
                return useTaskStore().removeTagFromAllTasks(tag);
            }
            catch (err) {
                throw err;
            }
        }
    }
});
