import { api } from 'boot/axios.js';

export default class TaskService {

    static sortItemsByLatest(items) {
        if (!items || !Array.isArray(items)) {
            return items;
        }
        return items.sort((a, b) => {
            const getValue = (dateTime) => dateTime ?? Infinity;
            if (getValue(a?.endDate) < getValue(b?.endDate)) return 1;
            if (getValue(a?.endDate) > getValue(b?.endDate)) return -1;
            return 0;
        });
    }

    static findLatestActivity(activities) {
        let latestActivity = null;
        for (const activity of activities) {
            const startDate = activity.startDate ?? Infinity;
            if (!latestActivity || startDate > latestActivity?.startDate) latestActivity = activity;
        }
        return latestActivity;
    }

    static findActivitiesWithinDateRange(task, startDate, endDate) {
        if (!startDate && !endDate) return task.activities;
        if (!startDate || !endDate) throw new Error('Must have a startDate and an endDate specified');
        return task.activities.filter(a => a.isWithinDateRange(startDate, endDate));
    }

    static async createTask(request) {
        return (await api.post('/tasks', {
            summary: request.summary,
            notes: request.notes,
            tags: request.tags,
            activities: request.activities
        })).data;
    }

    static async getTasks() {
        return (await api.get('/tasks')).data;
    }

    static async getTask(taskId) {
        return (await api.get(`/tasks/${taskId}`)).data;
    }

    static async updateTask(request) {
        return (await api.put(`/tasks/${request.id}`, {
            id: request.id,
            summary: request.summary,
            notes: request.notes,
            tags: request.tags,
            activities: request.activities.map(a => ({
                id: a.isPersisted() ? a.id : null,
                startDate: a.startDate.toISO(),
                endDate: a.endDate ? a.endDate.toISO() : null
            }))
        })).data;
    }

    static async deleteTask(taskId) {
        await api.delete(`/tasks/${taskId}`);
    }

    static async restoreTask(taskId) {
        return (await api.put(`/bin/tasks/${taskId}`)).data;
    }

    static async createTaskActivity(taskId, request) {
        return (await api.post(`/tasks/${taskId}/activities`, {
            startDate: request.startDate,
            endDate: request.endDate
        })).data;
    }

    static async getTaskActivities(taskId) {
        return (await api.get(`/tasks/${taskId}/activities`)).data;
    }

    static async updateTaskActivity(taskId, request) {
        return (await api.put(`/tasks/${taskId}/activities/${request.id}`, {
            id: request.id,
            startDate: request.startDate,
            endDate: request.endDate
        })).data;
    }

    static async deleteTaskActivity(taskId, activityId) {
        await api.delete(`/tasks/${taskId}/activities/${activityId}`);
    }

    static async restoreTaskActivity(taskId, activityId) {
        return (await api.put(`/bin/tasks/${taskId}/activities/${activityId}`)).data;
    }

    static async processBulkOperations(requests) {
        const response = await api.post('/tasks/bulk', requests);
        const successfulOperations = response.data.filter(o => o.status >= 200 && o.status < 300);
        const failedOperations = response.data.filter(o => o.status >= 300);
        return [ successfulOperations, failedOperations ];
    }

    static prepareBulkCreateRequests(tasks) {
        return tasks.map(task => ({
            op: 'post',
            ref: task.id,
            data: {
                summary: task.summary,
                notes: task.notes,
                tags: task.tags,
                activities: task.activities.map(activity => ({
                    startDate: activity.startDate.toISO(),
                    endDate: activity.endDate ? activity.endDate.toISO() : null
                }))
            }
        }));
    }
}
