import { Notify } from 'quasar';
import { register } from 'register-service-worker';
import { useAppStore } from 'stores/app-store.js';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

let initialInstall = true;

register(process.env.SERVICE_WORKER_FILE, {
    // The registrationOptions object will be passed as the second argument
    // to ServiceWorkerContainer.register()
    // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

    // registrationOptions: { scope: './' },

    ready(/* registration */) {
        console.log('Service worker is active.');
    },

    registered(/* registration */) {
        console.log('Service worker has been registered.');
    },

    cached(/* registration */) {
        console.log('Content has been cached for offline use.');
    },

    updatefound(/* registration */) {
        if (!initialInstall) {
            console.log('New content is downloading.');
            useAppStore().setUpdateNotification(
                Notify.create({
                    timeout: 5000,
                    progress: true,
                    icon: 'system_update',
                    message: 'A new version of the app is being installed, you will be prompted to reload shortly.'
                })
            );
        }
    },

    updated(/* registration */) {
        console.log('New content is available; please refresh.');
        useAppStore().clearUpdateNotification();
        useAppStore().appUpdated = true;
    },

    offline() {
        console.log('No internet connection found. App is running in offline mode.');
    },

    error(err) {
        console.error('Error during service worker registration:', err);
    }
});
