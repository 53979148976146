export class ClientError extends Error {

    constructor(...args) {
        super();
        this.name = this.constructor.name !== 'ClientError' ? this.constructor.name : 'Error';
        if (args.length === 0) return;
        if (args.length >= 1 && args[0] instanceof Error) {
            const error = args[0];
            this.stack = error.stack;
            this.message = args[1] || error.message;
            this.code = args[2];
            this.details = args[3];
        }
        else {
            this.message = args[0];
            this.code = args[1];
            this.details = args[2];
            this.stack = (new Error()).stack;
        }
    }
}

export class TaskStartError extends ClientError {
}

export class TaskStopError extends ClientError {
}

export class TaskSwitchError extends ClientError {
}
