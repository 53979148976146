import { customAlphabet } from 'nanoid';

/**
 * Generates a unique ID for local objects to be identified before being committed
 * to the server. All IDs start with 999 and is suffixed with the specified length
 * of random numbers.
 *
 * @param {number} length - The length of characters to be random.
 * @return {number}
 */
export function generateDraftId(length = 10) {
    const nanoid = customAlphabet('1234567890', length);
    return parseInt('999' + nanoid());
}
