import { route } from 'quasar/wrappers';
import { notifyError, openComponentDialog } from 'src/utils/quasar-util.js';
import { trackOnTitleUpdate } from 'src/utils/router-util';
import { useAppStore } from 'stores/app-store.js';
import { useAuthStore } from 'stores/auth-store.js';
import { defineAsyncComponent, nextTick } from 'vue';
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import routes, { publicOnlyRoutes, publicRoutes, RouteName } from './routes';

export let Router;

export default route(function (/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

    Router = createRouter({
        scrollBehavior: () => ({ left: 0, top: 0 }),
        routes,
        history: createHistory(process.env.VUE_ROUTER_BASE)
    });

    Router.beforeEach(async (to, from, next) => {
        const authStore = useAuthStore();
        if (authStore.returnRoute) {
            next(authStore.returnRoute);
            authStore.returnRoute = null;
            return;
        }
        await authStore.loadUser();
        if (authStore.user && (!authStore.hasFullName || !authStore.hasCountryCode)) {
            openComponentDialog(defineAsyncComponent(() => import('components/NewUserDialog.vue')));
            next(false);
            return;
        }
        if (authStore.isAuthenticated && publicOnlyRoutes.includes(to.name)) {
            next({ name: RouteName.VIEW_TASK_LIST });
            return;
        }
        if (!authStore.isAuthenticated && !publicRoutes.includes(to.name)) {
            next({ name: RouteName.START });
            return;
        }
        next();
    });

    Router.afterEach(to => nextTick(() => {
        // As some routes contain user data in the title or URL (i.e. IDs), we want to manually trigger page changes via
        // the Vue Router and ensure this data is not sent to the analytics tracker.
        trackOnTitleUpdate(to, { titleSuffixToRemove: ' - DoubleTime' });
    }));

    Router.onError((err) => {
        // Display an error if a lazy-loaded component cannot be loaded for some reason.
        const isModuleImportError = err.message.includes('Failed to fetch dynamically imported module');
        if (isModuleImportError && useAppStore().hasUpdateNotification) {
            notifyError('The app version is outdated, please reload the application and try again.');
        }
        else if (isModuleImportError) {
            notifyError('Oops, something went wrong, please reload the application and try again.');
        }
    });

    return Router;
});
