<script setup>
import { trackUmamiEvent } from '@jaseeey/vue-umami-plugin';
import AppUpdatedServiceDialog from 'components/service-dialog/AppUpdatedServiceDialog.vue';
import { useMeta, useQuasar } from 'quasar';
import { notifyError, openComponentDialog } from 'src/utils/quasar-util.js';
import { removeQueryParams } from 'src/utils/router-util.js';
import { useAppStore } from 'stores/app-store.js';
import { useAuthStore } from 'stores/auth-store.js';
import { useNotificationStore } from 'stores/notification-store.js';
import { usePwaStore } from 'stores/pwa-store.js';
import { useTimerStore } from 'stores/timer-store.js';
import { VOffline } from 'v-offline';
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { useRecaptchaProvider } from 'vue-recaptcha';
import { useRouter } from 'vue-router';

const metaConfig = {
    titleTemplate: title => title ? `${title} - DoubleTime` : 'DoubleTime'
};

const ClientOfflineServiceDialog = defineAsyncComponent(() => import('components/service-dialog/ClientOfflineServiceDialog.vue'));
const ServerOfflineServiceDialog = defineAsyncComponent(() => import('components/service-dialog/ServerOfflineServiceDialog.vue'));

const quasar = useQuasar();
const router = useRouter();
const appStore = useAppStore();
const authStore = useAuthStore();
const notificationStore = useNotificationStore();

usePwaStore(); // Initialize the PWA store, so it can promote installation if available.
useTimerStore(); // Initialize the timer store, so it detects changes to the active task.

const activeNotification = ref(null);

const clientOnline = computed({
    get: () => window.navigator.onLine,
    set: v => appStore.clientOffline = !v
});

function getNextNewNotification() {
    setTimeout(() => {
        const notifications = notificationStore.getNewNotifications();
        if (notifications.length === 0) return;
        activeNotification.value = notifications[0];
    }, 1500);
}

function dismissActiveNotification() {
    notificationStore.markNotificationAsSeen(activeNotification.value);
    activeNotification.value = null;
    getNextNewNotification();
}

function handleActiveNotificationAction() {
    window.open(activeNotification.value.actionUrl, '_blank');
    dismissActiveNotification();
}

function processAuthQueryParams() {
    router.isReady().then(() => {
        const hasAuthResult = !!router.currentRoute.value.query?.a || !!router.currentRoute.value.query?.n;
        const isAuthSuccess = Number(router.currentRoute.value.query?.a) === 1;
        const isAuthFailure = Number(router.currentRoute.value.query?.a) === 0;
        const isNewRegistration = Number(router.currentRoute.value.query?.n) === 1;
        if (!hasAuthResult) {
            return;
        }
        if (!authStore.isAuthenticated && isAuthFailure) {
            notifyError('Login failed: The email may already be associated with an account using another login service.');
            removeQueryParams('a');
        }
        if (authStore.isAuthenticated && isAuthSuccess) {
            trackUmamiEvent('user_login', { category: 'Auth', label: 'UserLoggedIn' });
            removeQueryParams('n');
        }
        if (authStore.isAuthenticated && isNewRegistration) {
            trackUmamiEvent('user_register', { category: 'Auth', label: 'UserRegistered' });
            removeQueryParams('n');
        }
    });
}

function processSubscribeQueryParams() {
    router.isReady().then(() => {
        const hasSubscribeResult = !!router.currentRoute.value.query?.s;
        const isSubscribeCompleted = Number(router.currentRoute.value.query?.s) === 1;
        const isSubscribeCancelled = Number(router.currentRoute.value.query?.s) === 0;
        if (!hasSubscribeResult) {
            return;
        }
        if (isSubscribeCompleted) {
            openComponentDialog(defineAsyncComponent(() => import('components/UserSubscribeCompletedDialog.vue')));
            trackUmamiEvent('user_subscribe_complete', { category: 'Subscription', label: 'UserSubscribed' });
        }
        if (isSubscribeCancelled) {
            openComponentDialog(defineAsyncComponent(() => import('components/UserSubscribeCancelledDialog.vue')));
            trackUmamiEvent('user_subscribe_cancel', { category: 'Subscription', label: 'UserCheckoutCancelled' });
        }
        removeQueryParams('s');
    });
}

function onNetworkChange(v) {
    return clientOnline.value = v;
}

onMounted(async () => {
    const appLoadingNotificationElement = document.getElementById('app-loading-notification');
    if (appLoadingNotificationElement) appLoadingNotificationElement.remove();
    document.body.style.backgroundColor = null;
    notificationStore.loadAllNotifications().then(() => getNextNewNotification());
    processAuthQueryParams();
    processSubscribeQueryParams();
});

useMeta(metaConfig);
useRecaptchaProvider();
</script>

<template>
    <VOffline @detected-condition="onNetworkChange" online-class="online" offline-class="offline"/>
    <AppUpdatedServiceDialog v-if="appStore.appUpdated" v-model="appStore.appUpdated"/>
    <ClientOfflineServiceDialog v-else-if="appStore.clientOffline" v-model="appStore.clientOffline"/>
    <ServerOfflineServiceDialog v-else-if="appStore.serverOffline" v-model="appStore.serverOffline"/>
    <router-view v-if="clientOnline"/>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown" appear>
        <q-banner v-if="activeNotification" class="notification-banner bg-accent text-white fixed-bottom" :inline-actions="!quasar.screen.lt.md">
            <span class="text-weight-medium">{{ activeNotification.message }}</span>
            <template v-slot:action>
                <q-btn
                    v-if="activeNotification.actionLabel && activeNotification.actionUrl"
                    @click="handleActiveNotificationAction"
                    :label="activeNotification.actionLabel"
                    outline
                />
                <q-btn @click="dismissActiveNotification" label="Dismiss" outline/>
            </template>
        </q-banner>
    </transition>
</template>

<style lang="scss" scoped>
.notification-banner {
    padding: 8px !important;
    padding-bottom: 4px !important;
    z-index: 9999;
}
</style>

<style lang="scss">
.notification-banner .q-banner__content {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 4px;
}

.notification-banner .q-banner__actions {
    margin-top: -12px;
}

.notification-banner .q-banner__actions .q-btn-item {
    margin: 12px 0 0 12px;
}
</style>
